// eslint-disable-next-line no-restricted-imports
import moment, { Moment } from 'moment-timezone';
import { Dispatch } from 'react';

import { AlarmCallbacks, AlarmDetails } from '@sms-smart-alarm';
import { alertError, alertSuccess } from '@utils/grafana';

import { SmartAlarmStreamType } from '../../context/SmartAlarmStreamContext';
import { FETOccurrenceProps, FET } from '../AlarmStream/AlarmFET/interfaces/alarm-fet.interface';

import { TimeRangeProps } from './utils.interface';

export const displayDate = (date: string): string => {
  const result: string = date !== '' ? moment(new Date(date)).format('DD.MM.YY - HH:mm:ss') : '- -';
  return result;
};

export const hasNoOccurrence = (fetOccurrences: FETOccurrenceProps[], timeRange: TimeRangeProps): boolean => {
  const hasOccurrence: boolean = fetOccurrences.some((occurrence: FETOccurrenceProps) => {
    const start: number = occurrence.endedAt ? new Date(`${occurrence.endedAt}`).getTime() : new Date().getTime();
    const end: number = occurrence.startedAt ? new Date(occurrence.startedAt).getTime() : new Date().getTime();
    return (
      (Math.min(start, end) <= timeRange.from.toDate().getTime() && Math.max(start, end) >= timeRange.to.toDate().getTime())
      || Math.min(start, end) >= timeRange.from.toDate().getTime() || Math.max(start, end) <= timeRange.to.toDate().getTime()
    );
  });
  return hasOccurrence;
};

export const showHiddenAlarm = async (alarmId: string, callbacks: AlarmCallbacks, dispatch: Dispatch<SmartAlarmStreamType>): Promise<void> => {
  try {
    const hideAlarmDetails: AlarmDetails = {
      alarmId: alarmId,
      hiddenFrom: null,
      hiddenTo: null,
      permanentlyHide: false,
    };
    const result: AlarmDetails[] = await callbacks.updateAlarmDetails([hideAlarmDetails]) as AlarmDetails[];
    dispatch({
      type: 'hiddenAlarms',
      payload: result
    });
    alertSuccess('Alarm shown successfully.');
  } catch (error) {
    alertError('Error showing alarm. Please try again.');
  }
};

export const dateTimeZone = (date: Date | string): Moment => {
  const timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezoneDateType: Moment = moment(new Date(`${date}Z`)).tz(timezone);
  const timezoneDateStringType: Moment = moment(new Date(date)).tz(timezone);
  return typeof date === 'string' ? timezoneDateStringType : timezoneDateType;
};

export const rootTree: FET = {
  name: 'root',
  subTree: [],
  alarms: [],
  id: '',
  errorCount: 0,
  warningCount: 0,
  plantPath: [],
  occurrences: [],
};
