import { Icon, Tooltip } from '@grafana/ui';
import React, { FC, ReactElement, useContext } from 'react';

import { SmartAlarmStreamContext } from '../../../../../context/SmartAlarmStreamContext';
import {
  AlarmDetailsPropertiesInterface,
  AlarmType,
} from '../../../../AlarmDetails/interfaces/alarm-details.interface';
import { hasNoOccurrence } from '../../../../utils/utils.model';
import { AlarmFetNodeProps, FetAlarmProps } from '../../interfaces/alarm-fet.interface';
import AlarmGantt from '../AlarmGantt/AlarmGantt';

import classes from './AlarmNode.module.scss';

const AlarmNode: FC<AlarmFetNodeProps> = ({ alarms, alarmLevel, timeRange }): ReactElement => {
  const alarmNode = alarms as FetAlarmProps[];
  const { state, dispatch } = useContext(SmartAlarmStreamContext);
  const { isLoading, selectedAlarm } = state;

  const renderAlarmGannt = (alarm: FetAlarmProps): JSX.Element => {
    return (
      <div className={classes['alarm-fet-parent-container']}>
        <div className={classes['fet-tree']} style={{ paddingLeft: `${alarmLevel! + 1.2}em` }}>
          <div className={classes['alarm-fet-parent-icon']}>
            <Icon name="exclamation-triangle" className={`${classes[`${alarm.severity}`]}`} />
          </div>
          <div className={classes['alarm-fet-parent-body']}>
            <Tooltip
              content={`${alarm.severity === 'error' ? 'Error -' : 'Warning -'} ${alarm.name}`}
              placement="top-start"
              theme="info"
            >
              <div className={classes.header}>{alarm.name}</div>
            </Tooltip>
            <div className={classes.icons}>
              <div className={classes['icons-error']}>
                <span>{alarm.id}</span>
              </div>
            </div>
          </div>
        </div>

        <div className={classes['fet-stream']}>
          <AlarmGantt alarm={alarm} />
        </div>
      </div>
    );
  };

  return (
    <>
      {alarmNode.map((alarm: FetAlarmProps, index: number) => {
        if (isLoading) {
          alarm.occurrences = [];
        }
        // this is to remove alarms with no occurrences
        const hasOccurrence = hasNoOccurrence(alarm.occurrences!, timeRange!);
        if (hasOccurrence) {
          return (
            <div
              key={index}
              className={classes['alarm-fet-parent']}
              style={{ backgroundColor: `${selectedAlarm.alarmId === alarm.id ? 'rgb(114 115 119 / 20%)' : ''}` }}
              onClick={() => {
                dispatch({ type: 'detailsPanelOpen', payload: true });
                dispatch({
                  type: 'addSelectedAlarm',
                  payload: {
                    alarmId: alarm.id,
                    alarmType: alarm.severity === 'error' ? AlarmType.ERROR : AlarmType.WARNING,
                    alarmName: alarm.name,
                    signal: alarm.tag,
                    events: alarm.occurrences?.length,
                    location: alarm.location,
                  } as AlarmDetailsPropertiesInterface,
                });
              }}
            >
              {renderAlarmGannt(alarm)}
            </div>
          );
        }
        return null;
      })}
    </>
  );
};

export default AlarmNode;
