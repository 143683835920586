import { Icon, Tooltip } from '@grafana/ui';
import countBy from 'lodash-es/countBy';
import flatMap from 'lodash-es/flatMap';
import map from 'lodash-es/map';
import uniqBy from 'lodash-es/uniqBy';
import React, { FC, ReactElement, useContext, useEffect, useState } from 'react';

import { SmartAlarmStreamContext } from '../../../../../context/SmartAlarmStreamContext';
import { hasNoOccurrence } from '../../../../utils/utils.model';
import { AlarmFetProps, FET, FetAlarmProps, FETOccurrenceProps } from '../../interfaces/alarm-fet.interface';
import AlarmGantt from '../AlarmGantt/AlarmGantt';
import AlarmNode from '../AlarmNode/AlarmNode';

import classes from './Tree.module.scss';

interface AlarmCountProps {
  errorCount: number;
  warningCount: number;
}

const Tree: FC<AlarmFetProps> = ({ fet, level, alarmLevel }): ReactElement => {
  const [selected, setSelected] = useState<boolean>(false);
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [alarmsCount, setAlarmsCount] = useState<AlarmCountProps>({ errorCount: 0, warningCount: 0 });
  const [topLevelOccurrences, setTopLevelOccurrences] = useState<FETOccurrenceProps[]>([]);
  const [expandedFetAlarms, setExpandedFetAlarms] = useState<boolean>(false);

  let fetNode = fet as FET;
  const hasChildren = fetNode.subTree?.length > 0;
  const hasAlarms = fetNode.alarms?.length > 0;
  const { state, dispatch } = useContext(SmartAlarmStreamContext);
  const { noAlarmOccurrences, isLoading, timeRange } = state;

  useEffect(() => {
    if (isLoading) {
      fetNode.occurrences = [];
    }
    flattenAndSetAlarmsCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetNode, isLoading]);

  const getAlarmsCount = (alarms: FetAlarmProps[]): AlarmCountProps => {
    const counts = countBy(alarms, 'severity');
    return {
      errorCount: counts.error || 0,
      warningCount: counts.warning || 0,
    };
  };

  const renderBranches = (): JSX.Element[] | null => {
    if (!hasChildren) {
      return null;
    }
    const newLevel = level! + 1;

    return fetNode.subTree.map((child: FET, index: number) => {
      if (isLoading) {
        child.occurrences = [];
      }
      const newAlarmLevel: number = hasChildren ? newLevel + 1 : newLevel;
      if (child.alarms.length) {
        const { errorCount, warningCount } = getAlarmsCount(child.alarms);
        child = { ...child, errorCount, warningCount };
      }
      return (
        <div key={index} onClick={() => alarmsWithNoOccurrences(fetNode.subTree[index], child.name, newLevel)}>
          <Tree fet={child} level={newLevel} alarmLevel={newAlarmLevel} />
        </div>
      );
    });
  };

  const getUniqueAlarmOccurrences = (alarms: FetAlarmProps[]): FETOccurrenceProps[] => {
    return alarms.flatMap(alarm =>
      uniqBy(
        alarm.occurrences,
        (occ: FETOccurrenceProps) => occ.severity && occ.id && occ.startedAt && occ.endedAt
      )
    );
  };

  const flattenAndSetAlarmsCount = (): void => {
    const flattenedSubTree: FET[] = flatMap(fetNode.subTree, (data: FET) =>
      data.subTree.length ? map(data.subTree, (fet: FET) => ({ ...fet })) : data
    );


    let alarmsTemp: FetAlarmProps[];
    let l = flattenedSubTree.length;
    if(l===0){
      alarmsTemp = fetNode.alarms;
    }
    else{
      alarmsTemp = [];
      for(let i = 0; i < l; i++){
        alarmsTemp = alarmsTemp.concat(flattenedSubTree[i].alarms);
      }
    }
    const alarms: FetAlarmProps[] = alarmsTemp;

    const uniqueAlarmOccurrences = getUniqueAlarmOccurrences(alarms);
    setTopLevelOccurrences(uniqueAlarmOccurrences);
    const { errorCount, warningCount } = getAlarmsCount(alarms);
    fetNode = { ...fetNode, errorCount, warningCount };
    setAlarmsCount({ errorCount: fetNode.errorCount, warningCount: fetNode.warningCount });
  };

  const toggleSelected = (): void => {
    setSelected((prev: boolean) => !prev);
    setIsClicked(!isClicked);
    if (hasAlarms) {
      setExpandedFetAlarms(!expandedFetAlarms);
    }
  };

  const alarmsWithNoOccurrences = (fetSubTree: FET, childName: string, newLevel: number): void => {
    const hasNoChild =
      (!fetSubTree.subTree.length && !fetSubTree.alarms.length && fetSubTree.name === childName) ||
      fetSubTree.alarms.length;

    const alarmList = fetSubTree.alarms.filter((item: FetAlarmProps) => hasNoOccurrence(fetSubTree.occurrences!, timeRange));
    if (newLevel > 0 && hasNoChild) {
      const hasAlarm = noAlarmOccurrences?.some((alarm: string) => alarm === childName);
      let filteredAlarmNames: string[] = [];
      let allAlarms: string[] = [];
      if (hasAlarm) {
        filteredAlarmNames = noAlarmOccurrences?.filter((alarm: string) => alarm !== childName);
        allAlarms = [...filteredAlarmNames];
      } else if (!hasAlarm && !alarmList.length) {
        allAlarms = [...(noAlarmOccurrences || []), childName];
      }
      dispatch({
        type: 'noAlarmOccurrences',
        payload: [...new Set(allAlarms)],
      });
    }
  };

  return (
    <>
      <div className={classes['fet-parent']}>
        <div className={classes['fet-parent-container']} onClick={toggleSelected}>
          <div className={classes['fet-tree']} style={{ paddingLeft: `${level}em` }}>
            <div className={classes['fet-parent-icon']}>
              <Icon name={`${isClicked ? 'angle-down' : 'angle-right'}`} size="lg" />
            </div>
            <div className={classes['fet-parent-body']}>
              <Tooltip content={`${fetNode.name}`} placement="top-start" theme="info">
                <div className={classes.header}>{fetNode.name}</div>
              </Tooltip>
              <div className={classes.icons}>
                <div className={classes['icons-error']}>
                  <Icon name="exclamation-triangle" className={classes.error} />
                  <span>{alarmsCount.errorCount}</span>
                </div>
                <div className={classes['icons-warning']}>
                  <Icon name="exclamation-triangle" className={classes.warning} />
                  <span>{alarmsCount.warningCount}</span>
                </div>
              </div>
            </div>
          </div>
          <div className={classes['fet-stream']}>
            <AlarmGantt fetNode={fetNode} topLevelOccurrences={topLevelOccurrences} />
          </div>
        </div>
        {hasAlarms && expandedFetAlarms && (
          <AlarmNode alarms={fetNode.alarms} timeRange={timeRange} alarmLevel={!alarmLevel ? 1 : alarmLevel} />
        )}
      </div>
      {selected && renderBranches()}
    </>
  );
};

export default Tree;
