// eslint-disable-next-line no-restricted-imports
import moment, { Duration } from 'moment';

import {
  FrameProps,
  GridlineProps,
  LIVE_MODE_FRAMES,
  HISTORY_MODE_FRAMES,
  TimeRangeProps,
  CurrentDateProps,
} from '../utils/utils.interface';

import { calculateCoordinate } from './AlarmFET/components/AlarmGantt/AlarmGantt.model';

const HOUR_AS_MS = 3600000;
const DAY_AS_MS = 8.64e7;
const YEAR_AS_MS = 3.154e10;
const ONE_DAY = 1;
const TEN_DAYS = 10;
const SIX_MONTHS_IN_DAYS = 180;
const YEAR_IN_DAYS = 366;
const YEAR_SIX = 6;

export const timestampIntervals = (frame: TimeRangeProps, streamWidth: number): GridlineProps[] => {
  const from: Date = frame.from.toDate();
  const to: Date = frame.to.toDate();
  const momentDuration: Duration = moment.duration(moment(to).diff(moment(from)));
  const gridResult: GridlineProps[] = createGridlinesArray(momentDuration, frame, streamWidth);
  return gridResult;
};

export const roundMinutesTo = (date: moment.Moment, minutes = 5): moment.Moment => {
  const rounded = Math.round(date.minutes() / minutes) * minutes;
  return date.minute(rounded).seconds(0).milliseconds(0);
};

export const checkIfDateIsCurrent = (from: Date, to: Date): CurrentDateProps => {
  const momentDuration: Duration = moment.duration(moment(to).diff(moment(from)));
  const daysDuration: number = Math.round(momentDuration.asDays());
  const yearDuration: number = Math.round(momentDuration.asYears());
  const isCurrentYear: boolean = daysDuration >= YEAR_IN_DAYS && yearDuration <= YEAR_SIX;
  const currentDate: string = moment().format('YYYY-MM-DDTHH:mm');
  const endDate: string = moment(to).format('YYYY-MM-DDTHH:mm');
  return {
    isCurrentYear,
    isLive: endDate >= currentDate,
  };
};

const createGridlinesArray = (
  momentDuration: Duration,
  frame: TimeRangeProps,
  streamWidth: number
): GridlineProps[] => {
  const millisecondsDuration: number = Math.round(momentDuration.asMilliseconds());
  const daysDuration: number = Math.round(momentDuration.asDays());
  const hoursDuration: number = Math.round(momentDuration.asHours());
  let gridlines: GridlineProps[] = [];
  const modeFrames: FrameProps[] = daysDuration <= ONE_DAY ? LIVE_MODE_FRAMES : HISTORY_MODE_FRAMES;
  const gridDefinition = modeFrames.reduce((prev: FrameProps, curr: FrameProps) => {
    return Math.abs(curr.duration - millisecondsDuration) < Math.abs(prev.duration - millisecondsDuration)
      ? curr
      : prev;
  });
  let updatedGridDefinition: FrameProps = { ...gridDefinition };

  if (daysDuration <= ONE_DAY) {
    const diff: number = hoursDuration - gridDefinition.humanized!;
    const duration: number = diff > 0 ? millisecondsDuration + diff * HOUR_AS_MS - HOUR_AS_MS : gridDefinition.duration;
    updatedGridDefinition = {
      ...updatedGridDefinition,
      duration: Math.abs(duration),
      segmentDuration: ONE_DAY,
    };
  }

  if (daysDuration > ONE_DAY && daysDuration < SIX_MONTHS_IN_DAYS) {
    const diff: number = daysDuration - gridDefinition.humanized!;
    const duration: number = diff > 0 ? millisecondsDuration + diff * DAY_AS_MS - DAY_AS_MS : gridDefinition.duration;
    const segmentDuration: number =
      diff > 0 ? diff * DAY_AS_MS - DAY_AS_MS + gridDefinition.sectionDuration : gridDefinition.sectionDuration;
    updatedGridDefinition = {
      ...gridDefinition,
      duration: Math.abs(duration),
      sectionDuration: Math.abs(segmentDuration),
    };
  }
  gridlines = createGrids(updatedGridDefinition, frame, streamWidth);
  return gridlines;
};

const splitDateIntoEqualIntervals = (startDate: Date, endDate: Date, numberOfIntervals: number): Date[] => {
  const intervalLength: number = (endDate.getTime() - startDate.getTime()) / numberOfIntervals;
  return [...new Array(numberOfIntervals)].map(
    (_, index: number) => new Date(startDate.getTime() + (index + 1) * intervalLength)
  );
};

const convertIndexToMoment = (start: number, index: number, segmentDuration: number): number => {
  const time: number = start + index * segmentDuration;
  return Math.floor(time / segmentDuration) * segmentDuration;
};

const createGrids = (gridDefinition: FrameProps, frame: TimeRangeProps, streamWidth: number): GridlineProps[] => {
  let gridlines: GridlineProps[] = [];
  const from: Date = frame.from.toDate();
  const to: Date = frame.to.toDate();
  const momentDuration: Duration = moment.duration(moment(to).diff(moment(from)));
  const minutesDuration: number = momentDuration.asMinutes();
  const daysDuration: number = Math.round(momentDuration.asDays());
  const yearDuration: number = Math.round(momentDuration.asYears());

  if (daysDuration < YEAR_IN_DAYS) {
    const sectionLineCount = gridDefinition.duration / gridDefinition.sectionDuration;
    const segmentLineCount = gridDefinition.sectionDuration / gridDefinition.segmentDuration!;
    // generate the default section/segment lines starting after the segment padding
    // controls where on the timeline to start the lines; +1 if we've added segment padding so we start after it
    const timelines = sectionLineCount * segmentLineCount; // controls where on the timeline to end the lines
    for (let i = 0; i < timelines; ++i) {
      const time: number = convertIndexToMoment(from.getTime(), i, gridDefinition.segmentDuration!);
      if (time % gridDefinition.sectionDuration === 0) {
        if (time < from.getTime()) {
          continue;
        }
        const left: number = calculateCoordinate(time, streamWidth, 0, from.getTime(), to.getTime());
        gridlines.push({
          formattedTime: formattedDate(time, daysDuration, minutesDuration),
          left: Math.abs(left),
        });
      }
    }
  } else {
    if (daysDuration >= YEAR_IN_DAYS && yearDuration <= YEAR_SIX) {
      const intervalTime = daysDuration <= YEAR_IN_DAYS ? YEAR_AS_MS / 6 : YEAR_AS_MS;
      const interval: number = Math.round((to.getTime() - from.getTime()) / intervalTime);
      const result: Date[] = splitDateIntoEqualIntervals(from, to, interval);
      for (let i = 0; i < result.length; ++i) {
        const time: number = result[i].getTime();
        if (time < from.getTime()) {
          continue;
        }
        const left: number = calculateCoordinate(time, streamWidth, 0, from.getTime(), to.getTime());
        if (formattedDate(time, daysDuration, minutesDuration) !== moment(new Date()).format('YYYY')) {
          gridlines.push({
            formattedTime: formattedDate(time, daysDuration, minutesDuration),
            left: Math.abs(left),
          });
        }
      }
    }
  }
  return gridlines;
};

const formattedDate = (time: number, daysDuration: number, minutesDuration: number): string => {
  if (daysDuration <= ONE_DAY) {
    return minutesDuration <= TEN_DAYS ? moment(time).format('HH:mm') : roundMinutesTo(moment(time)).format('HH:mm');
  }
  if (daysDuration > YEAR_IN_DAYS) {
    return moment(time).format('YYYY');
  }
  return moment(time).format('DD.MM.YYYY');
};
