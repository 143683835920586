// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.B1zCXFtLLvH0Fyk9V7IW{width:100%;padding:.8em 0;font-weight:bold}.B1zCXFtLLvH0Fyk9V7IW .zC8OQDbQ1A86ipugTmpk{display:flex;position:relative;height:1.6rem;overflow-x:clip}.B1zCXFtLLvH0Fyk9V7IW .zC8OQDbQ1A86ipugTmpk .w8ShSXnq76bHkwdU99gR{position:absolute;width:max-content;margin-left:-15px}.B1zCXFtLLvH0Fyk9V7IW .zC8OQDbQ1A86ipugTmpk .w8ShSXnq76bHkwdU99gR:before{content:"▲";position:absolute;margin-top:20px;left:9px}.B1zCXFtLLvH0Fyk9V7IW .zC8OQDbQ1A86ipugTmpk .AsKmynybe5ENM7L29p7c{width:max-content;border-left:1px dotted #fff;position:absolute;top:2.5rem}`, "",{"version":3,"sources":["webpack://./components/AlarmStream/Timestamps/Timestamps.module.scss"],"names":[],"mappings":"AAAA,sBAGA,UACE,CAAA,cACA,CAAA,gBACA,CAAA,4CAEA,YACE,CAAA,iBACA,CAAA,aACA,CAAA,eACA,CAAA,kEAEA,iBACE,CAAA,iBACA,CAAA,iBACA,CAAA,yEAEA,WACE,CAAA,iBACA,CAAA,eACA,CAAA,QACA,CAAA,kEAIJ,iBACE,CAAA,2BACA,CAAA,iBACA,CAAA,UACA","sourcesContent":["$border-color: #cccccc;\r\n$limegreen: #8de971;\r\n\r\n.timestampContainer {\r\n  width: 100%;\r\n  padding: 0.8em 0;\r\n  font-weight: bold;\r\n\r\n  .timestampBody {\r\n    display: flex;\r\n    position: relative;\r\n    height: 1.6rem;\r\n    overflow-x: clip;\r\n\r\n    .formattedTime {\r\n      position: absolute;\r\n      width: max-content;\r\n      margin-left: -15px;\r\n\r\n      &:before {\r\n        content: '\\25B2';\r\n        position: absolute;\r\n        margin-top: 20px;\r\n        left: 9px;\r\n      }\r\n    }\r\n\r\n    .timestampItem {\r\n      width: max-content;\r\n      border-left: 1px dotted white;\r\n      position: absolute;\r\n      top: 2.5rem;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timestampContainer": `B1zCXFtLLvH0Fyk9V7IW`,
	"timestampBody": `zC8OQDbQ1A86ipugTmpk`,
	"formattedTime": `w8ShSXnq76bHkwdU99gR`,
	"timestampItem": `AsKmynybe5ENM7L29p7c`
};
export default ___CSS_LOADER_EXPORT___;
