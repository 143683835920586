import { Tooltip } from '@grafana/ui';
import React, { FC, Fragment, ReactElement, useContext, useEffect, useState } from 'react';

import { SmartAlarmStreamContext } from '../../../context/SmartAlarmStreamContext';
import { GridlineProps } from '../../utils/utils.interface';
import { timestampIntervals } from '../AlarmStreamBody.model';

import classes from './Timestamps.module.scss';

const Timestamps: FC = (): ReactElement => {
  const { state } = useContext(SmartAlarmStreamContext);
  const { streamWidth, alarmStreamHeight } = state;
  const [intervals, setIntervals] = useState<GridlineProps[]>([]);

  useEffect(() => {
    const result: GridlineProps[] = timestampIntervals(state.timeRange, streamWidth);
    setIntervals(result);
  }, [streamWidth, state.timeRange]);

  return (
    <div className={classes.timestampContainer}>
      <div className={classes.timestampBody}>
        {intervals.map((interval: GridlineProps, index: number) => (
          <Fragment key={index}>
            <div
              className={classes.formattedTime}
              style={{
                left: `${interval.left}px`,
              }}
            >
              <Tooltip content={`${interval.formattedTime}`} placement="top-start" theme="info">
                <div>{interval.formattedTime}</div>
              </Tooltip>
            </div>
            <div
              className={classes.timestampItem}
              style={{
                left: `${interval.left}px`,
                height: `${alarmStreamHeight}px`,
              }}
            ></div>
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default Timestamps;
