import { Icon } from '@grafana/ui';
import React, { Dispatch, FC, ReactElement, SetStateAction, useState } from 'react';
import DatePicker from 'react-datepicker';

import { DateProps, DateRangeTime } from '../../modal.interface';

import classes from './PlainDatePicker.module.scss';

interface PlainDatePickerProps {
  label: string;
  selected: Date | null | undefined;
  startDate: Date | null;
  endDate: Date | null;
  minDate?: Date | null;
  maxDate?: Date | null;
  selectsStart: boolean;
  selectsEnd: boolean;
  dateRangeTime: number[];
  setDate: Dispatch<SetStateAction<DateProps | any>>;
  setDateRangeTime: Dispatch<SetStateAction<DateRangeTime>>
}

const TWENTY_TWO = 22;
const TWENTY_THREE = 23;
const TWENTY_FOUR = 24;
const FIFTY_EIGHT = 58;
const FIFTY_NINE = 59;
const SIXTY = 60;

export const PlainDatePicker: FC<PlainDatePickerProps> = ({
  label,
  selected,
  startDate,
  endDate,
  maxDate,
  minDate,
  selectsStart,
  selectsEnd,
  dateRangeTime,
  setDate,
  setDateRangeTime
}): ReactElement => {
  const [timePicker, setTimePicker] = useState<number[]>(dateRangeTime);

  const updatePickerData = (data: number[]): void => {
    const dataUpdate = {...(label === 'from' && { from: data }), ...(label === 'to' && { to: data })};
    setTimePicker([...data]);
    setDateRangeTime((dataRange: DateRangeTime) => ({...dataRange, ...dataUpdate}));
  };

  return (
    <div className={classes.dateContainer}>
      <p className={classes.datePickerLabel}>{label}</p>
      <div className={classes.datePicker}>
        <DatePicker
          calendarClassName="datePickerWrapper"
          selected={selected}
          onChange={setDate}
          startDate={startDate}
          endDate={endDate}
          timeIntervals={1}
          maxDate={maxDate}
          minDate={minDate}
          selectsStart={selectsStart}
          selectsEnd={selectsEnd}
          selectsRange
          inline
          fixedHeight
          disabledKeyboardNavigation
          dateFormat={'M/d/yyyy h:mm aa'}
        />
      </div>
      <div className={classes.timePickerContainer}>
        <div className={classes.timePickerContainerTable}>
          <table>
            <tbody>
              <tr>
                <td onClick={() => {
                  const data: number[] = [...timePicker];
                  data[0]++;
                  if (data[0] >= TWENTY_FOUR) {
                    data[0] = 0;
                    data[1] += data[1] <= FIFTY_EIGHT ? 1 : 0;
                  }
                  updatePickerData(data);
                }}>
                  <div className={classes.anchorBtn}>
                    <Icon name={'angle-up'} size={'xl'} />
                  </div>
                </td>
                <td>&nbsp;&nbsp;&nbsp;</td>
                <td onClick={() => {
                  const data: number[] = [...timePicker];
                  data[1]++;
                  if (data[1] >= SIXTY) {
                    data[1] = 0;
                    data[0] += data[0] <= TWENTY_TWO ? 1 : 0;
                  }
                  updatePickerData(data);
                }}>
                  <div className={classes.anchorBtn}>
                    <Icon name={'angle-up'} size={'xl'} />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    type="text"
                    pattern="[0-9]*"
                    maxLength={2}
                    value={timePicker[0] > 9 ? timePicker[0] : `0${timePicker[0]}`}
                    className="text-center"
                    placeholder="HH"
                  />
                </td>
                <td>&nbsp;:&nbsp;</td>
                <td>
                  <input
                    type="number"
                    pattern="[0-9]*"
                    maxLength={2}
                    value={timePicker[1] > 9 ? timePicker[1] : `0${timePicker[1]}`}
                    className="text-center"
                    placeholder="MM"
                  />
                </td>
              </tr>
              <tr>
                <td onClick={() => {
                  const data: number[] = [...timePicker];
                  data[0]--;
                  if (data[0] <= -1) {
                    data[0] = TWENTY_THREE;
                    data[1] -= data[1] > 0 ? 1 : -FIFTY_NINE;
                  }
                  updatePickerData(data);
                }}>
                  <div className={classes.anchorBtn}>
                    <Icon name={'angle-down'} size={'xl'} />
                  </div>
                </td>
                <td>&nbsp;&nbsp;&nbsp;</td>
                <td onClick={() => {
                  const data: number[] = [...timePicker];
                  data[1]--;
                  if (data[1] <= -1 || data[1] >= SIXTY) {
                    data[1] = FIFTY_NINE;
                    data[0] -= data[0] >= 1 ? 1 : -TWENTY_THREE;
                  }
                  updatePickerData(data);
                }}>
                  <div className={classes.anchorBtn}>
                    <Icon name={'angle-down'} size={'xl'} />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PlainDatePicker;
