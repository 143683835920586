// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RT6cpjqTEM6xmaZFmgeJ{width:100%}.MJ2TSsfU89NkwKFA_AT4{width:100%;padding:.8em;font-weight:bold}.n39qIpx2tUFfWKVj8KTi{width:100%}.h3hIVcdHy0tjxJXKxZTD{position:relative;border-bottom:none}.P2tsW5spTs3xYieO3iOZ{position:absolute;top:0;left:0;bottom:0;pointer-events:none;background-color:rgba(255,255,255,.2);border-left:solid 1px rgba(255,255,255,.3);border-right:solid 1px rgba(255,255,255,.3);opacity:0;transition:opacity linear .25s}`, "",{"version":3,"sources":["webpack://./components/AlarmStream/AlarmFET/AlarmFET.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CAEA,sBACE,UAAA,CACA,YAAA,CACA,gBAAA,CAGF,sBACE,UAAA,CAEA,sBACE,iBAAA,CACA,kBAAA,CAKN,sBACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,QAAA,CACA,mBAAA,CACA,qCAAA,CACA,0CAAA,CACA,2CAAA,CACA,SAAA,CACA,8BAAA","sourcesContent":[".fet-container {\n  width: 100%;\n\n  &-header {\n    width: 100%;\n    padding: 0.8em;\n    font-weight: bold;\n  }\n\n  &-body {\n    width: 100%;\n\n    &-content {\n      position: relative;\n      border-bottom: none;\n    }\n  }\n}\n\n.drag-select-area {\n  position: absolute;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  pointer-events: none;\n  background-color: rgba(255, 255, 255, .2);\n  border-left: solid 1px rgba(255, 255, 255, .3);\n  border-right: solid 1px rgba(255, 255, 255, .3);\n  opacity: 0;\n  transition: opacity linear .25s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fet-container": `RT6cpjqTEM6xmaZFmgeJ`,
	"fet-container-header": `MJ2TSsfU89NkwKFA_AT4`,
	"fet-container-body": `n39qIpx2tUFfWKVj8KTi`,
	"fet-container-body-content": `h3hIVcdHy0tjxJXKxZTD`,
	"drag-select-area": `P2tsW5spTs3xYieO3iOZ`
};
export default ___CSS_LOADER_EXPORT___;
