/* eslint-disable no-restricted-imports */
import moment from 'moment';
import React, { FC, ReactElement, useContext, useEffect, useState } from 'react';

import { SmartAlarmStreamContext } from '../../../context/SmartAlarmStreamContext';
import { CurrentDateProps } from '../../utils/utils.interface';
import { checkIfDateIsCurrent } from '../AlarmStreamBody.model';

import classes from './Live.module.scss';

const LiveDisplay: FC = (): ReactElement => {
  const { state } = useContext(SmartAlarmStreamContext);
  const { timeRange, isLoading } = state;
  const [showIsLive, setShowIsLive] = useState<CurrentDateProps>({
    isCurrentYear: false,
    isLive: false
  });

  useEffect(() => {
    const result: CurrentDateProps = checkIfDateIsCurrent(timeRange.from.toDate(), timeRange.to.toDate());
    setShowIsLive(result);
  }, [timeRange]);

  return (
    <>
      {!isLoading ? (
        <div className={classes.streamDate}>
          {showIsLive.isLive ? (
            <span className={classes.streamDateText}>
              {!showIsLive.isCurrentYear ? 'Live' : 'Current Year'} (
              {!showIsLive.isCurrentYear
                ? moment(timeRange.to.toDate()).format('HH:mm:ss')
                : moment(timeRange.to.toDate()).format('YYYY, HH:mm:ss')}
              )
            </span>
          ) : (
            <span className={classes.streamDateText}>
              {timeRange.from.toDate().toLocaleString()} - {timeRange.to.toDate().toLocaleString()}
            </span>
          )}
        </div>
      ) : <></>}
    </>
  );
};

export default LiveDisplay;
