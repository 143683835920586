import { UrlQueryMap } from '@grafana/data';
import { locationService } from '@grafana/runtime';
import { RefObject, MouseEvent } from 'react';

import { TimeRangeProps } from './utils.interface';

export default class DragZoom {
  dragAreaRef: RefObject<HTMLDivElement>;
  bodyContentRef: RefObject<HTMLDivElement>;
  timeRange: TimeRangeProps;
  dragStartX: number;
  lastDrag: number;

  constructor(
    dragAreaRef: RefObject<HTMLDivElement>,
    bodyContentRef: RefObject<HTMLDivElement>,
    timeRange: TimeRangeProps
  ) {
    this.dragAreaRef = dragAreaRef;
    this.bodyContentRef = bodyContentRef;
    this.timeRange = timeRange;
    this.dragStartX = -1;
    this.lastDrag = 0;
  }

  public dragStart(event: MouseEvent): void {
    const dragStart: number = this.getDragPosition(event);
    this.dragStartX = dragStart;
  }

  public dragMove(event: MouseEvent): void {
    if (this.dragStartX === -1) {
      return;
    }

    //calculate where the drag area should be
    const dragStart: number = this.getDragPosition(event);

    const dragDelta: number = dragStart - this.dragStartX;
    this.dragAreaRef.current!.style.width = dragDelta > 0 ? `${dragDelta}px` : `${-dragDelta}px`;
    this.dragAreaRef.current!.style.left = dragDelta > 0 ? `${this.dragStartX}px` : `${dragStart}px`;

    //Unhide / hide the area if we're dragging enough or not
    this.dragAreaRef.current!.style.opacity = this.dragStartX !== -1 && Math.abs(dragDelta) > 30 ? '1' : '0';
  }

  public dragStop(event: MouseEvent): void {
    let dragStart: number = this.getDragPosition(event);

    if (this.dragStartX !== -1 && Math.abs(this.dragStartX - dragStart) > 30) {
      event.stopPropagation();
      event.preventDefault();
      this.lastDrag = new Date().getTime();

      const bodyRect = this.bodyContentRef.current!.getBoundingClientRect();

      const offset: number = bodyRect.width * 0.2;

      //calculate the new selected timerange
      this.dragStartX -= offset;
      dragStart -= offset;
      const rangeStart: number = this.timeRange.from.toDate().getTime();
      const rangeEnd: number = this.timeRange.to.toDate().getTime();
      const rangeWidth: number = bodyRect.width * 0.8;

      const newStart = (rangeEnd - rangeStart) * (this.dragStartX / (rangeWidth));
      const newEnd = (rangeEnd - rangeStart) * (dragStart / (rangeWidth));

      const start = Math.round(Math.min(newStart, newEnd) + rangeStart);
      const end = Math.round(Math.max(newStart, newEnd) + rangeStart);

      //set new timerange in grafana
      let queryMap: UrlQueryMap = { from: start, to: end };
      locationService.partial(queryMap);

      this.reset();
    }
    this.dragStartX = -1;
  }

  public reset() {
    this.dragAreaRef.current!.style.opacity = '0';
    this.dragAreaRef.current!.style.left = '0px';
    this.dragAreaRef.current!.style.width = '0px';
  }

  public captureClick(event: MouseEvent): void {
    if (new Date().getTime() - this.lastDrag < 100) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  public dragAbort(): void {
    this.dragAreaRef.current!.style.borderLeft = '';
    this.dragAreaRef.current!.style.borderRight = '';
    this.dragAreaRef.current!.style.left = '0px';
    this.dragAreaRef.current!.style.width = '0px';
    this.dragStartX = -1;
  }

  private getDragPosition(event: MouseEvent): number {
    const bodyRect = this.bodyContentRef.current!.getBoundingClientRect();
    return Math.max(bodyRect.width * 0.2, event.clientX - bodyRect.left);
  }
}
