import { AlarmDetails } from '@sms-smart-alarm';

export interface AlarmDetailsPropertiesInterface {
  alarmId: string;
  alarmType: AlarmType;
  alarmName: string;
  signal: string;
  location: string;
  events: number;
  hiddenAlarms: AlarmDetails[];
}

export enum AlarmType {
  WARNING,
  ERROR,
}
