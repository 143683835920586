import { Button } from '@grafana/ui';
import moment, { Moment } from 'moment-timezone';
import React, { ReactElement, useContext, useState } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import { AlarmDetails } from '@sms-smart-alarm';
import { alertError, alertSuccess } from '@utils/grafana';

import { SmartAlarmStreamContext } from '../../../context/SmartAlarmStreamContext';
import { AlarmModalWrapper } from '../AlarmModalWrapper';
import { DateRangePicker } from '../date-picker/date-range-picker/DateRangePicker';
import { DateProps, DateRangeTime, HideAlarmModalProps } from '../modal.interface';

import classes from './HideAlarmModal.module.scss';

export const HideAlarmModal: React.FC<HideAlarmModalProps> = ({
  alarm,
  activeModal,
  callbacks,
  setActiveModal,
}): ReactElement => {
  const {
    dispatch,
  } = useContext(SmartAlarmStreamContext);
  const [isToggled, setIsToggled] = useState<boolean>(false);
  const [enableSaveButton, setEnableSaveButton] = useState<boolean>(false);
  const [date, setDate] = useState<DateProps>({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [dateRangeTime, setDateRangeTime] = useState<DateRangeTime>({
    from: [new Date().getHours(), new Date().getMinutes()],
    to: [new Date().getHours(), new Date().getMinutes()],
  });

  const addHiddenAlarmTime = (hideAlarmDetails: AlarmDetails): AlarmDetails => {
    if (hideAlarmDetails.hiddenFrom && hideAlarmDetails.hiddenTo) {
      (hideAlarmDetails.hiddenFrom as Date).setHours(dateRangeTime.from[0]);
      (hideAlarmDetails.hiddenFrom as Date).setMinutes(dateRangeTime.from[1]);
      (hideAlarmDetails.hiddenTo as Date).setHours(dateRangeTime.to[0]);
      (hideAlarmDetails.hiddenTo as Date).setMinutes(dateRangeTime.to[1]);
      hideAlarmDetails.hiddenFrom = moment(new Date(`${hideAlarmDetails.hiddenFrom}`)).format();
      hideAlarmDetails.hiddenTo = moment(new Date(`${hideAlarmDetails.hiddenTo}`)).format();
    }
    return hideAlarmDetails;
  };

  const saveHiddenAlarm = async (): Promise<void> => {
    try {
      const momentEndDate: Moment = moment(new Date(`${date.endDate}`));
      if (
        !isToggled && dateRangeTime.from[0] > dateRangeTime.to[0] ||
        (dateRangeTime.from[0] === dateRangeTime.to[0] && dateRangeTime.from[1] > dateRangeTime.to[1])
      ) {
        alertError('"Start" time cannot be more than "End" time.');
      } else if (!isToggled && momentEndDate.isBefore(moment().format(), 'day')) {
        alertError('"End" date cannot be less than "Current" date.');
      } else if (
        !isToggled && momentEndDate.isSame(moment().format(), 'day') &&
        dateRangeTime.from[0] === dateRangeTime.to[0] &&
        dateRangeTime.from[1] === dateRangeTime.to[1]
      ) {
        alertError('"Start" time cannot be same "End" time.');
      } else {
        let hideAlarmDetails: AlarmDetails = {
          alarmId: alarm.alarmId,
          // optionally adding properties to object
          ...(!isToggled && { hiddenFrom: date.startDate }),
          ...(!isToggled && { hiddenTo: date.endDate }),
          permanentlyHide: isToggled,
        } as AlarmDetails;
        hideAlarmDetails = addHiddenAlarmTime(hideAlarmDetails);
        const result: AlarmDetails[] = (await callbacks.saveAlarmDetails(hideAlarmDetails)) as AlarmDetails[];
        dispatch({
          type: 'hiddenAlarms',
          payload: result,
        });
        setActiveModal();
        alertSuccess('Alarm was hidden successfully.');
      }
    } catch (error) {
      alertError('Error hiding alarm. Please try again.');
    }
  };

  return (
    <AlarmModalWrapper
      headerText={'HIDE THIS ALARM'}
      activeModal={activeModal}
      modalType="notifications"
      minHeight={750}
      alarmType="Error"
      alarm={alarm}
      closeModal={() => setActiveModal()}
    >
      <div></div>
      <>
        <div className={classes.modalSwitch}>
          <div className={classes.modalContentAlarmInfo}>
            <div
              className={`${classes.checkbox} ${isToggled ? classes.active : ''}`}
              onClick={() => {
                setIsToggled(!isToggled);
                setEnableSaveButton(!isToggled);
                setDateRangeTime({
                  from: [new Date().getHours(), new Date().getMinutes()],
                  to: [new Date().getHours(), new Date().getMinutes()],
                });
              }}
            ></div>
            <label
              className={classes.toggleLabel}
              onClick={() => {
                setIsToggled(!isToggled);
                setEnableSaveButton(!isToggled);
                setDateRangeTime({
                  from: [new Date().getHours(), new Date().getMinutes()],
                  to: [new Date().getHours(), new Date().getMinutes()],
                });
              }}
            >
              <b className={classes.mainLabel}>Hide alarm permanently</b>
            </label>
          </div>
        </div>
        <div className={classes.contentGrid}>
          {!isToggled && (
            <div className={`${classes.contentLine} ${classes.timeFrameSelection}`}>
              <p className={classes.dateRangeHeader}>Hide for the following timeframe</p>
              <DateRangePicker
                firstLabel="from"
                secondLabel="to"
                setDate={(data: DateProps) => {
                  setDate(data);
                  setEnableSaveButton(true);
                }}
                dateRangeTime={dateRangeTime}
                setDateRangeTime={setDateRangeTime}
              />
            </div>
          )}
          <div className={classes.borderBottom}></div>
          <div className={classes.footer}>
            <button onClick={() => setActiveModal()}>CANCEL</button>
            <Button variant={'primary'} disabled={!enableSaveButton} onClick={saveHiddenAlarm}>
              SAVE
            </Button>
          </div>
        </div>
      </>
    </AlarmModalWrapper>
  );
};
