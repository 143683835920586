import { addDays } from 'date-fns';
import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';

import { DateProps, DateRangeTime } from '../../modal.interface';
import PlainDatePicker from '../plain-date-picker/PlainDatePicker';

import classes from './DateRangePicker.module.scss';

interface DateRangePickerProps {
  firstLabel: string;
  secondLabel: string;
  setDate: Dispatch<SetStateAction<DateProps | any>>;
  dateRangeTime: DateRangeTime;
  setDateRangeTime: Dispatch<SetStateAction<DateRangeTime>>;
}

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  firstLabel,
  secondLabel,
  dateRangeTime,
  setDate,
  setDateRangeTime
}): ReactElement => {
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const numberOfDaysInStartMonth: Date = new Date(new Date().getFullYear(), new Date().getMonth()+1, 0);
  const remainingDays: number = numberOfDaysInStartMonth.getDate() - new Date().getDate();
  const firstDateOfMonth: Date = new Date(new Date().getFullYear(), new Date().getMonth(), 1);

  return (
    <div className={classes.dateRange}>
      <div className={classes.dateRangeInputBody}>
        <div className={`${classes.dateRangeBodyPicker} ${classes.dateRangeInputPickerLeft}`}>
          <PlainDatePicker
            label={firstLabel}
            selected={startDate}
            setDate={(dateRes: [Date | null, Date | null]) => {
              setStartDate(dateRes[0]);
              setDate((dateInfo: DateProps) => ({...dateInfo, startDate: dateRes[0] }));
            }}
            setDateRangeTime={setDateRangeTime}
            selectsStart={true}
            selectsEnd={false}
            startDate={startDate}
            endDate={endDate}
            minDate={firstDateOfMonth}
            dateRangeTime={dateRangeTime.from}
            maxDate={addDays(new Date(`${startDate}`), remainingDays)}
          />
        </div>
        <div className={classes.space}>–</div>
        <div className={`${classes.dateRangeBodyPicker} ${classes.dateRangeInputPickerRight}`}>
          <PlainDatePicker
            label={secondLabel}
            selected={endDate}
            setDate={(dateRes: [Date | null, Date | null]) => {
              setEndDate(dateRes[0]);
              setDate((dateInfo: DateProps) => ({...dateInfo, endDate: dateRes[0]}));
            }}
            setDateRangeTime={setDateRangeTime}
            selectsStart={false}
            selectsEnd={true}
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            dateRangeTime={dateRangeTime.to}
          />
        </div>
      </div>
    </div>
  );
};
