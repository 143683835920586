import React, { FC, ReactElement } from 'react';

import { AlarmTooltipProperties } from '../../../../../utils/utils.interface';
import { displayDate } from '../../../../../utils/utils.model';

import classes from './AlarmTooltip.module.scss';

export const AlarmTooltip: FC<AlarmTooltipProperties> = ({ occurrence }): ReactElement => {
  return (
    <div className={classes.alarmTooltip}>
      <table className={classes.occurrencesTable}>
        <tr>
          <th>Coming:</th>
          <td>{displayDate(occurrence.startedAt)}</td>
        </tr>
        <tr>
          <th>Going:</th>
          {occurrence.endedAt && <td>{displayDate(occurrence.endedAt ?? '')}</td>}
          {!occurrence.endedAt && (
            <td>
              <span>- -</span>
            </td>
          )}
        </tr>
      </table>
      <span className={classes.triangle}></span>
    </div>
  );
};
