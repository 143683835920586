import { DataFrame, Field, PanelData, TimeRange } from '@grafana/data';
import { useTheme2, Icon } from '@grafana/ui';
import React, { ChangeEvent, FC, ReactElement, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { AlarmCallbacks, AlarmDetails as AlarmDetailsProps } from '@sms-smart-alarm';

import { SmartAlarmStreamCallbackContext, SmartAlarmStreamContext } from '../../context/SmartAlarmStreamContext';
import logo from '../../img/logo.svg';
import { constructTree } from '../../utils/tree';
import { AlarmDetails } from '../AlarmDetails/AlarmDetails';
import { checkIfDateIsCurrent } from '../AlarmStream/AlarmStreamBody.model';
import LiveDisplay from '../AlarmStream/Live/Live';
import Timestamps from '../AlarmStream/Timestamps/Timestamps';
import { CurrentDateProps } from '../utils/utils.interface';

import { FET } from './../AlarmStream/AlarmFET/interfaces/alarm-fet.interface';
import AlarmStream from './../AlarmStream/AlarmStream';

import './SmartAlarmStreamContainer.scss';

interface SmartAlarmStreamProps {
  data: PanelData;
  width: number;
  height: number;
  timeRange: TimeRange;
  hiddenAlarms: AlarmDetailsProps[];
}

const getTree = (frames: DataFrame[], filterWatchList: boolean, hiddenAlarms: AlarmDetailsProps[]): FET => {
  let lastField: Field | null = null;

  frames.forEach((frame) => {
    if (frame.name !== 'alarms') {
      return;
    }

    frame.fields.forEach((field) => {
      lastField = field;
    });
  });

  if (lastField === null) {
    return {
      name: 'root',
      subTree: [],
      alarms: [],
      id: '',
      errorCount: 0,
      warningCount: 0,
      plantPath: [],
      occurrences: [],
    };
  }

  return constructTree([...(lastField as Field).values], filterWatchList, hiddenAlarms);
};

const SmartAlarmStreamContainer: FC<SmartAlarmStreamProps> = ({
  data,
  height,
  width,
  timeRange,
  hiddenAlarms: sourceHiddenAlarms,
}): ReactElement => {
  const theme = useTheme2();
  const {
    state: { detailsPanelOpen, isLoading, filterWatchList, hiddenAlarms },
    dispatch,
  } = useContext(SmartAlarmStreamContext);
  const [showIsLive, setShowIsLive] = useState<CurrentDateProps>({
    isCurrentYear: false,
    isLive: false
  });
  const rulerRef = useRef<HTMLDivElement>(null);
  const callbacks: AlarmCallbacks = (data.series[0].fields[0] as any).callbacks;

  const tree = useMemo(() => {
    return getTree(data.series, filterWatchList, hiddenAlarms);
  }, [data.series, filterWatchList, hiddenAlarms]);

  useEffect(() => {
    dispatch({
      type: 'hiddenAlarms',
      payload: sourceHiddenAlarms,
    });
  }, [dispatch, sourceHiddenAlarms]);

  useEffect(() => {
    dispatch({
      type: 'dataUpdate',
      payload: {
        tree: tree,
        timeRange: timeRange,
      },
    });
    const result: CurrentDateProps = checkIfDateIsCurrent(timeRange.from.toDate(), timeRange.to.toDate());
    setShowIsLive(result);
  }, [dispatch, timeRange, tree]);

  useEffect(() => {
    dispatch({ type: 'isLoading', payload: data.state !== 'Done' });
    if (!rulerRef.current) {
      return;
    }
    const resizeObserver: ResizeObserver = new ResizeObserver(() => {
      dispatch({ type: 'streamWidth', payload: rulerRef.current?.clientWidth || 0 });
    });
    resizeObserver.observe(rulerRef.current);
    return () => resizeObserver.disconnect();
  }, [dispatch, data, filterWatchList]);

  const changeWatchList = (event: ChangeEvent) => {
    dispatch({
      type: 'filterWatchList',
      payload: (event.target as HTMLInputElement).checked
    });
  };

  const renderContent = (): React.JSX.Element | null => {
    if (tree.subTree.length === 0) {
      return renderNoData();
    }

    if (tree.subTree.length > 0) {
      return (
        <div className="content-body">
          <div className="liveDisplay">
            <LiveDisplay />
          </div>
          <div className={`table ${showIsLive.isLive ? 'liveLine' : 'notLiveLine'} ${isLoading ? 'loading' : ''}`}>
            <div className="timestamps">
              <div className="timeline">Timeline</div>
              <div className="timestampGrids">
                <div ref={rulerRef}></div>
                <Timestamps />
              </div>
            </div>
            <div className="alarmStreams">
              <AlarmStream />
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  const renderSpinner = (): React.JSX.Element | null => {
    if (isLoading) {
      return (
        <div className="data-loading">
          <Icon name="fa fa-spinner" size="xxl" className="spinner"></Icon>
        </div>
      );
    }

    return null;
  };

  const renderNoData = (): React.JSX.Element => {
    return (
      <div className={`no-data-info ${isLoading ? 'noDataLoading' : ''}`}>
        <span>No data available in the selected time range.</span>
      </div>
    );
  };

  return (
    <>
      <SmartAlarmStreamCallbackContext.Provider value={{ callbacks }}>
        <div className={`${theme.isDark ? 'dark-theme' : 'dark-light'} smartAlarmMain`} style={{ height, width }}>
          <div className="smartAlarmPanel">
            <img className="logo" src={logo} alt={'Smart Alarm Logo'} data-cy={'smart-alarm-logo'} />
            <div className="content">
              <div className="watchlist-container"><input type="checkbox" onChange={changeWatchList}></input><span>Only show watchlist</span></div>
              {renderContent()}
              {renderSpinner()}
            </div>
          </div>
          {detailsPanelOpen && (
            <div className="sideCard">
              <AlarmDetails callbacks={callbacks} />
            </div>
          )}
        </div>
      </SmartAlarmStreamCallbackContext.Provider>
    </>
  );
};

export default SmartAlarmStreamContainer;
