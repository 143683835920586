import { Icon, Modal } from '@grafana/ui';
import React, { ReactElement } from 'react';

import classes from './AlarmModalWrapper.module.scss';
import { AlarmProps } from './modal.interface';

interface AlarmModalWrapperProps {
  alarm: AlarmProps;
  headerText: string;
  activeModal: boolean;
  alarmType: string;
  modalType: string;
  minHeight: number;
  closeModal: () => void;
  children: JSX.Element[];
}

export const AlarmModalWrapper: React.FC<AlarmModalWrapperProps> = ({
  children,
  headerText,
  activeModal,
  alarmType,
  alarm,
  modalType,
  minHeight,
  closeModal,
}): ReactElement => {
  function renderTitle(title: string): JSX.Element {
    return (
      <div className={classes.modalHeader}>
        <div className={classes.headerHeadline}>
          <h3>{title}</h3>
        </div>
        <div className={classes.headerControls}>
          {children[0] && children[0]}
          <div className={classes.headerControlsClose} onClick={closeModal}>
            ✕
          </div>
        </div>
      </div>
    );
  }

  return (
    <Modal
      title={renderTitle(headerText)}
      isOpen={activeModal}
      className={`${modalType === 'solutions' ? classes.modalDialog : classes.modalNotificationDialog}`}
    >
      <div className={classes.modalBody} style={{ minHeight: `${minHeight}px` }}>
        <div className={classes.modalOptionsSubHeader}>
          <div className={classes.modalContentAlarmInfo}>
            <div className={`${classes.severity} ${alarmType === 'Warning' ? classes.warning : classes.error}`}>
              <Icon name={'exclamation-triangle'} className={classes.infoIcon} />
              &nbsp;
              <span>{alarmType}</span>
            </div>
            <div className={classes.message}>
              <span>{alarm.name}</span>
            </div>
          </div>
        </div>
        {children[1]}
      </div>
    </Modal>
  );
};
