// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.B1zCXFtLLvH0Fyk9V7IW{width:100%;padding:.8em 0;font-weight:bold}.B1zCXFtLLvH0Fyk9V7IW .zC8OQDbQ1A86ipugTmpk{display:flex;position:relative;height:1.6rem;overflow-x:clip}.B1zCXFtLLvH0Fyk9V7IW .zC8OQDbQ1A86ipugTmpk .w8ShSXnq76bHkwdU99gR{position:absolute;width:max-content;margin-left:-15px}.B1zCXFtLLvH0Fyk9V7IW .zC8OQDbQ1A86ipugTmpk .w8ShSXnq76bHkwdU99gR:before{content:"▲";position:absolute;margin-top:20px;left:9px}.B1zCXFtLLvH0Fyk9V7IW .zC8OQDbQ1A86ipugTmpk .AsKmynybe5ENM7L29p7c{width:max-content;border-left:1px dotted #fff;position:absolute;top:2.5rem}`, "",{"version":3,"sources":["webpack://./components/AlarmStream/Timestamps/Timestamps.module.scss"],"names":[],"mappings":"AAAA,sBAGA,UACE,CAAA,cACA,CAAA,gBACA,CAAA,4CAEA,YACE,CAAA,iBACA,CAAA,aACA,CAAA,eACA,CAAA,kEAEA,iBACE,CAAA,iBACA,CAAA,iBACA,CAAA,yEAEA,WACE,CAAA,iBACA,CAAA,eACA,CAAA,QACA,CAAA,kEAIJ,iBACE,CAAA,2BACA,CAAA,iBACA,CAAA,UACA","sourcesContent":["$border-color: #cccccc;\n$limegreen: #8de971;\n\n.timestampContainer {\n  width: 100%;\n  padding: 0.8em 0;\n  font-weight: bold;\n\n  .timestampBody {\n    display: flex;\n    position: relative;\n    height: 1.6rem;\n    overflow-x: clip;\n\n    .formattedTime {\n      position: absolute;\n      width: max-content;\n      margin-left: -15px;\n\n      &:before {\n        content: '\\25B2';\n        position: absolute;\n        margin-top: 20px;\n        left: 9px;\n      }\n    }\n\n    .timestampItem {\n      width: max-content;\n      border-left: 1px dotted white;\n      position: absolute;\n      top: 2.5rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timestampContainer": `B1zCXFtLLvH0Fyk9V7IW`,
	"timestampBody": `zC8OQDbQ1A86ipugTmpk`,
	"formattedTime": `w8ShSXnq76bHkwdU99gR`,
	"timestampItem": `AsKmynybe5ENM7L29p7c`
};
export default ___CSS_LOADER_EXPORT___;
