import { dateTime } from '@grafana/data';
import React, { FC, ReactNode, createContext, useReducer, Dispatch, ReactElement, Context } from 'react';

import { AlarmCallbacks, AlarmDetails } from '@sms-smart-alarm';

import { AlarmDetailsPropertiesInterface } from '../components/AlarmDetails/interfaces/alarm-details.interface';
import { FET } from '../components/AlarmStream/AlarmFET/interfaces/alarm-fet.interface';
import { TimeRangeProps } from '../components/utils/utils.interface';
import { rootTree } from '../components/utils/utils.model';

interface InitialUpdateType {
  tree: FET;
  timeRange: TimeRangeProps;
}

interface TimeUpdate {
  timeRange: TimeRangeProps;
}

export interface SmartAlarmStreamType {
  type: string;
  payload:
    | InitialUpdateType
    | FET
    | AlarmDetailsPropertiesInterface
    | TimeUpdate
    | string[]
    | boolean
    | object
    | AlarmDetails[]
    | number;
}

export interface SmartAlarmStreamContextProps {
  detailsPanelOpen: boolean;
  tree: FET;
  isLoading: boolean;
  noAlarmOccurrences: string[];
  selectedAlarm: AlarmDetailsPropertiesInterface;
  timeRange: TimeRangeProps;
  hiddenAlarms: AlarmDetails[];
  streamWidth: number;
  alarmStreamHeight: number;
  filterWatchList: boolean;
}

interface SmartAlarmStreamContextType {
  state: SmartAlarmStreamContextProps;
  dispatch: Dispatch<SmartAlarmStreamType>;
}

const initialSelectedAlarmState: AlarmDetailsPropertiesInterface = {
  alarmId: '',
  alarmType: 0,
  alarmName: '',
  signal: '',
  location: '',
  events: 0,
  hiddenAlarms: [],
};

const initialValues = {
  detailsPanelOpen: false,
  tree: rootTree,
  isLoading: false,
  noAlarmOccurrences: [],
  selectedAlarm: initialSelectedAlarmState,
  timeRange: {
    from: dateTime(new Date()),
    to: dateTime(new Date()),
    raw: { from: 'now-6h', to: 'now' },
  },
  hiddenAlarms: [],
  streamWidth: 0,
  alarmStreamHeight: 0,
  filterWatchList: false,
};

interface SmartAlarmStreamCallbackType {
  callbacks: AlarmCallbacks | null;
}

export const SmartAlarmStreamContext: Context<SmartAlarmStreamContextType> = createContext<SmartAlarmStreamContextType>(
  {
    state: initialValues,
    dispatch: () => null,
  }
);

export const SmartAlarmStreamCallbackContext: Context<SmartAlarmStreamCallbackType> =
  createContext<SmartAlarmStreamCallbackType>({
    callbacks: null,
  });

const mainReducer = (
  state: SmartAlarmStreamContextProps,
  action: SmartAlarmStreamType
): SmartAlarmStreamContextProps => ({
  detailsPanelOpen: stateReducer(state, action).detailsPanelOpen,
  tree: stateReducer(state, action).tree,
  isLoading: stateReducer(state, action).isLoading,
  noAlarmOccurrences: stateReducer(state, action).noAlarmOccurrences,
  selectedAlarm: stateReducer(state, action).selectedAlarm,
  timeRange: stateReducer(state, action).timeRange,
  hiddenAlarms: stateReducer(state, action).hiddenAlarms,
  streamWidth: stateReducer(state, action).streamWidth,
  alarmStreamHeight: stateReducer(state, action).alarmStreamHeight,
  filterWatchList: stateReducer(state, action).filterWatchList,
});

const SmartAlarmStreamProvider: FC<ReactNode> = ({ children }): ReactElement => {
  const [state, dispatch] = useReducer(mainReducer, initialValues);

  return <SmartAlarmStreamContext.Provider value={{ state, dispatch }}>{children}</SmartAlarmStreamContext.Provider>;
};

const stateReducer = (
  state: SmartAlarmStreamContextProps,
  action: SmartAlarmStreamType
): SmartAlarmStreamContextProps => {
  switch (action.type) {
    case 'dataUpdate': {
      const { tree, timeRange } = action.payload as InitialUpdateType;
      return { ...state, tree: { ...tree }, timeRange: { ...timeRange } };
    }
    case 'detailsPanelOpen':
      return { ...state, detailsPanelOpen: action.payload as boolean };
    case 'tree':
      return { ...state, tree: { ...(action.payload as FET) }};
    case 'isLoading':
      return { ...state, isLoading: action.payload as boolean };
    case 'noAlarmOccurrences':
      return { ...state, noAlarmOccurrences: [...(action.payload as string[])] };
    case 'hiddenAlarms':
      return { ...state, hiddenAlarms: [...(action.payload as AlarmDetails[])] };
    case 'addSelectedAlarm':
      return { ...state, selectedAlarm: { ...(action.payload as AlarmDetailsPropertiesInterface) } };
    case 'clearSelectedAlarm':
      return { ...state, selectedAlarm: { ...initialSelectedAlarmState } };
    case 'streamWidth': {
      let streamWidth = action.payload as number;
      return { ...state, streamWidth };
    }
    case 'alarmStreamHeight': {
      return { ...state, alarmStreamHeight: action.payload as number };
    }
    case 'filterWatchList': {
      return { ...state, filterWatchList: action.payload as boolean};
    }
    default: {
      return state;
    }
  }
};

export default SmartAlarmStreamProvider;
