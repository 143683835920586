import { CoordinateProps } from './interfaces/gantt.interface';

export const calculateCoordinates = (
  notificationStart: number,
  notificationEnd: number,
  maxPx: number,
  minPx: number,
  minRange: number,
  maxRange: number,
): CoordinateProps => {
  let x1 = calculateCoordinate(notificationStart, maxPx, minPx, minRange, maxRange);
  let x2 = calculateCoordinate(notificationEnd, maxPx, minPx, minRange, maxRange);

  if (x1 < 0) {
    x1 = 0;
  };
  if (x2 > maxPx) {
    x2 = maxPx;
  }
  return { x1, x2 };
};

export const calculateCoordinate = (
  time: number,
  maxPx: number,
  minPx: number,
  minRange: number,
  maxRange: number,
): number => {
  let pos = ((maxPx - minPx) * (time - minRange)) / (maxRange - minRange) + minPx;
  pos = pos | 0;
  return pos;
};
