import { Button, Modal } from '@grafana/ui';
import React, { ReactElement } from 'react';

import classes from './ConfirmModalContainer.module.scss';

interface ConfirmModalProps {
  type: string;
  isOpen: boolean;
  headerText: string;
  modalType: string;
  buttons: Buttons;
  setConfirmCancel?(confirm: boolean): void;
  setConfirmDelete?(confirm: boolean): void;
}

interface Buttons {
  btnOneText: string;
  btnTwoText: string;
  btnOneHandler: () => void;
  btnTwoHandler: () => void;
}

export const ConfirmModalContainer: React.FC<ConfirmModalProps> = ({
  type,
  isOpen,
  headerText,
  modalType,
  buttons,
  setConfirmCancel,
  setConfirmDelete,
}): ReactElement => {
  const { btnOneText, btnTwoText, btnOneHandler, btnTwoHandler } = buttons;

  function renderTitle(type: string): JSX.Element {
    return (
      <div className={classes.modalHeader}>
        <div className={classes.headerHeadline}>
          <h3>PLEASE CONFIRM</h3>
        </div>
        <div
          className={classes.headerControls}
          onClick={() => {
            if (type === 'delete' && setConfirmDelete) {
              setConfirmDelete(false);
            } else if (type === 'confirm' && setConfirmCancel) {
              setConfirmCancel(false);
            }
          }}
        >
          <div className={classes.headerControlsClose}>✕</div>
        </div>
      </div>
    );
  }

  return (
    <Modal
      title={renderTitle(type)}
      isOpen={isOpen}
      className={`${modalType === 'solutions' ? classes.modalSolutionDialog : classes.modalDialog}`}
    >
      <>
        <div className={classes.contentLine}>
          <div className={classes.confirmDialogMessage}>{headerText}</div>
        </div>
        <div className={classes.confirmDialogButtons}>
          <Button variant={'secondary'} onClick={btnOneHandler}>
            {btnOneText}
          </Button>
          <Button onClick={btnTwoHandler}>{btnTwoText}</Button>
        </div>
      </>
    </Modal>
  );
};
