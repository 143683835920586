// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.SAE6k68OUe9zDK33ZH8p{position:absolute;display:flex;align-self:center;height:70%}div.SAE6k68OUe9zDK33ZH8p:hover{z-index:10}.Iq82dpxBgJKic7Ex9B4b{background:#f1ae15;z-index:2}.Iq82dpxBgJKic7Ex9B4b:hover{background:rgba(241,174,21,.1)}.gYZfcpcz3IHWu9q3sDC8{background:#e32c2c;z-index:3}.gYZfcpcz3IHWu9q3sDC8:hover{background:rgba(227,44,44,.1)}`, "",{"version":3,"sources":["webpack://./components/AlarmStream/AlarmFET/components/AlarmGantt/GanttUnit.module.scss"],"names":[],"mappings":"AAGA,yBACE,iBAAA,CACA,YAAA,CACA,iBAAA,CACA,UAAA,CACA,+BACE,UAAA,CAIJ,sBACE,kBAdQ,CAeR,SAAA,CACA,4BACE,8BAAA,CAGJ,sBACE,kBApBM,CAqBN,SAAA,CACA,4BACE,6BAAA","sourcesContent":["$warning: #f1ae15;\n$error: #e32c2c;\n\ndiv.ganttUnit {\n  position: absolute;\n  display: flex;\n  align-self: center;\n  height: 70%;\n  &:hover {\n    z-index: 10;\n  }\n}\n\n.warning {\n  background: $warning;\n  z-index: 2;\n  &:hover {\n    background: rgba($warning, 0.1);\n  }\n}\n.error {\n  background: $error;\n  z-index: 3;\n  &:hover {\n    background: rgba($error, 0.1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ganttUnit": `SAE6k68OUe9zDK33ZH8p`,
	"warning": `Iq82dpxBgJKic7Ex9B4b`,
	"error": `gYZfcpcz3IHWu9q3sDC8`
};
export default ___CSS_LOADER_EXPORT___;
