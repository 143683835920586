import { Button, Icon, TextArea } from '@grafana/ui';
import React, { BaseSyntheticEvent, ReactElement, useEffect, useRef, useState } from 'react';

import { AlarmCallbacks, Solution } from '@sms-smart-alarm';
import { alertError, alertSuccess } from '@utils/grafana';

import { AlarmModalWrapper } from '../AlarmModalWrapper';
import { AlarmProps } from '../modal.interface';

import classes from './AlarmSolutionsModal.module.scss';
import { ConfirmModalContainer } from './ConfirmModalContainer';
import { SolutionDisplay } from './SolutionDisplay';

interface AlarmSolutionsProps {
  alarm: AlarmProps;
  solutions: Solution[];
  activeModal: boolean;
  setActiveModal(active: boolean): void;
  setSolutions(solutions: Solution[]): void;
  callbacks: AlarmCallbacks;
}

interface AlarmSolutionUpdate {
  add: boolean;
  edit: boolean;
}

export const AlarmSolutionsModal: React.FC<AlarmSolutionsProps> = ({
  alarm,
  solutions,
  activeModal,
  setActiveModal,
  setSolutions,
  callbacks,
}): ReactElement => {
  const closedRef = useRef<boolean>(false);
  const [solutionUpdate, setSolutionUpdate] = useState<AlarmSolutionUpdate>({
    add: false,
    edit: false,
  });
  const [confirmCancel, setConfirmCancel] = useState<boolean>(false);
  const [content, setContent] = useState<string>('');
  const [alarmSolutions, setAlarmSolutions] = useState<Solution[]>([...solutions]);
  const contentRef = useRef<string>(content ?? '');
  const [selectedContent, setSelectedContent] = useState<Solution | null>(null);

  useEffect(() => {
    setAlarmSolutions([...solutions]);
  }, [solutions]);

  const cancel = () => {
    setConfirmCancel(false);
    setSolutionUpdate({ ...solutionUpdate, add: false });
    setContent('');
  };

  const saveSolution = async (): Promise<void> => {
    try {
      if (!content.length) {
        return;
      }
      await callbacks.saveAlarmSolution(alarm.alarmId, content);
      setSolutionUpdate({ ...solutionUpdate, add: false });
      const solutionAlarm: Solution = {
        alarmId: alarm.alarmId,
        solutionId: `${solutions.length + 1}`,
        content: content,
        createdAt: new Date(),
      };
      setSolutions([solutionAlarm, ...alarmSolutions]);
      setAlarmSolutions((solution: Solution[]) => [solutionAlarm, ...solution]);
      setContent('');
      alertSuccess('Alarm solution added successfully.');
    } catch (error) {
      alertError('Error adding a new alarm solution.');
    }
  };

  const updateSolution = async (solution: Solution): Promise<void> => {
    try {
      if (!`${solution.content}`.length) {
        return;
      }
      await callbacks.updateAlarmSolution(solution.solutionId, `${solution.content}`);
      const alarmSolutionIndex: number = solutions!.findIndex(
        (alarmSolution: Solution) => alarmSolution.solutionId === solution.solutionId
      ) as number;
      const updatedAlarmSolutions = [...alarmSolutions];
      updatedAlarmSolutions.splice(alarmSolutionIndex, 1, solution);
      setAlarmSolutions([...updatedAlarmSolutions]);
      setSolutions([...updatedAlarmSolutions]);
      alertSuccess('Alarm solution updated successfully.');
    } catch (error) {
      alertError('Error updating alarm solution.');
    }
  };

  const deleteSolution = async (solutionId: string): Promise<void> => {
    try {
      await callbacks.deleteAlarmSolution(solutionId);
      const filteredAlarmSolutions: Solution[] = [
        ...solutions!.filter((solution: Solution) => solution.solutionId !== solutionId),
      ];
      setAlarmSolutions([...filteredAlarmSolutions]);
      setSolutions([...filteredAlarmSolutions]);
      alertSuccess('Alarm solution deleted successfully.');
    } catch (error) {
      alertError('Error deleting alarm solution.');
    }
  };

  return (
    <AlarmModalWrapper
      headerText={'Solutions for this alarm'}
      activeModal={activeModal}
      modalType="solutions"
      minHeight={600}
      alarmType="Error"
      alarm={alarm}
      closeModal={() => {
        closedRef.current = true;
        setActiveModal(false);
        setSolutionUpdate({ ...solutionUpdate, add: false });
        setSelectedContent({} as Solution);
      }}
    >
      <Button
        onClick={() => setSolutionUpdate({ ...solutionUpdate, add: !solutionUpdate.add })}
        disabled={solutionUpdate.add || solutionUpdate.edit}
        data-cy={'add-solution-alarm'}
      >
        <Icon name={'plus'} />
        ADD SOLUTION
      </Button>
      <>
        {solutionUpdate.add && (
          <div className={classes.solutionInputs}>
            <div className={classes.solutionDescription}>
              <div className={classes.solutionsControls}>
                <div className={classes.btnGroup}>
                  <button className={'btn btnDefault disabled'}>
                    <i className="fa fa-pencil"></i>
                  </button>
                  <button className={'btn btnDefault disabled'}>
                    <i className="fa fa-trash"></i>
                  </button>
                </div>
              </div>
              <TextArea
                className={classes.solutionTextarea}
                rows={6}
                placeholder="Please add a solution for this alarm type..."
                onChange={(event) => {
                  setContent((event as BaseSyntheticEvent).target.value);
                }}
                value={content}
                data-cy={'content-solution-alarm'}
              />
              <div className={classes.solutionButtons}>
                <Button
                  variant={'secondary'}
                  onClick={() => {
                    if (contentRef.current !== content) {
                      setConfirmCancel(true);
                    } else {
                      setSolutionUpdate({ ...solutionUpdate, add: false });
                    }
                  }}
                  data-cy={'discard-changes-solution-alarm'}
                >
                  DISCARD SOLUTION
                </Button>
                <Button onClick={saveSolution} data-cy={'save-solution-alarm'}>
                  SAVE SOLUTION
                </Button>
                <ConfirmModalContainer
                  isOpen={confirmCancel}
                  type={'confirm'}
                  modalType={'solutions'}
                  headerText={'Do you really want to discard your changes?'}
                  buttons={{
                    btnOneText: 'CONTINUE EDITING SOLUTION',
                    btnTwoText: 'DISCARD CHANGES',
                    btnOneHandler: () => setConfirmCancel(false),
                    btnTwoHandler: () => cancel(),
                  }}
                  setConfirmCancel={() => setConfirmCancel(false)}
                />
              </div>
            </div>
          </div>
        )}
        <div className={classes.modalOptionsContent}>
          <div className={classes.solutionsModal}>
            <div className={classes.contentLine}>
              <ul>
                {alarmSolutions.map((solution: Solution) => (
                  <li
                    key={solution.solutionId}
                    className={`${
                      selectedContent?.solutionId && solution.solutionId !== selectedContent.solutionId
                        ? classes.disabledItem
                        : ''
                    }`}
                  >
                    <SolutionDisplay
                      solution={solution}
                      selectContent={(item) => {
                        setSelectedContent(item);
                        setSolutionUpdate({ ...solutionUpdate, edit: !solutionUpdate.edit });
                      }}
                      deleteSolution={deleteSolution.bind(this, solution.solutionId)}
                      updateSolution={updateSolution.bind(this, solution)}
                    />
                  </li>
                ))}
              </ul>
              {solutions.length === 0 && !solutionUpdate.add && (
                <div className={`${classes.solutionAdd} empty`}>
                  <h5>There are no solutions for this alarm type yet.</h5>
                  <Button
                    onClick={() => setSolutionUpdate({ ...solutionUpdate, add: !solutionUpdate.add })}
                    data-cy={'add-solution-alarm'}
                  >
                    <Icon name={'plus'} />
                    ADD SOLUTION
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    </AlarmModalWrapper>
  );
};
