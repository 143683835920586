import { Icon } from '@grafana/ui';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment';
import React, { FC, MouseEvent, ReactElement, useContext } from 'react';

import { AlarmCallbacks, AlarmDetails } from '@sms-smart-alarm';

import {
  SmartAlarmStreamCallbackContext,
  SmartAlarmStreamContext,
} from '../../../../../context/SmartAlarmStreamContext';
import { dateTimeZone, showHiddenAlarm } from '../../../../utils/utils.model';
import { AlarmGanntProps, FETOccurrenceProps } from '../../interfaces/alarm-fet.interface';

import classes from './AlarmGantt.module.scss';
import { GanttUnit } from './GanttUnit';

const AlarmGantt: FC<AlarmGanntProps> = ({ fetNode, alarm, topLevelOccurrences }): ReactElement => {
  const {
    state: { noAlarmOccurrences, hiddenAlarms },
    dispatch,
  } = useContext(SmartAlarmStreamContext);
  const { callbacks } = useContext(SmartAlarmStreamCallbackContext);

  let occurrences: FETOccurrenceProps[] | undefined = (fetNode ?? alarm)?.occurrences ?? [];
  occurrences = topLevelOccurrences ? topLevelOccurrences : occurrences;

  const showSelectedHiddenAlarm = async (event: MouseEvent, alarmId: string): Promise<void> => {
    event.stopPropagation();
    await showHiddenAlarm(alarmId, callbacks as AlarmCallbacks, dispatch);
  };

  const getHiddenAlarm = (occurrence: FETOccurrenceProps): AlarmDetails | undefined => {
    const hiddenAlarm = hiddenAlarms.find((alarm: AlarmDetails) => {
      const hiddenFrom = alarm.hiddenFrom ? dateTimeZone(alarm.hiddenFrom) : null;
      return (
        alarm.alarmId === occurrence.alarmId &&
        (alarm.permanentlyHide || (alarm.hiddenFrom && alarm.hiddenTo && moment().isSameOrAfter(hiddenFrom!.format())))
      );
    });
    return hiddenAlarm;
  };

  const renderFETOccurrence = (occurrence: FETOccurrenceProps, index: number): JSX.Element | JSX.Element[] | null => {
    const hiddenAlarm = getHiddenAlarm(occurrence);
    if (hiddenAlarm && occurrence.alarmId === hiddenAlarm.alarmId) {
      return null;
    }
    return occurrence.factoryElementIds.map((fetItem: string) => {
      const fetList: string[] = fetItem.split('-');
      if (fetList.includes(fetNode?.name ?? '')) {
        return <GanttUnit key={index} occurrence={occurrence} disableTooltip={true} />;
      }

      return (
        <div key={index} className={classes.noActiveAlarm}>
          {noAlarmOccurrences?.includes(`${fetNode?.name}`) && fetNode?.subTree.length === 0 && (
            <div>Currently no active alarms.</div>
          )}
        </div>
      );
    });
  };

  const renderOccurrence = (occurrence: FETOccurrenceProps, index: number): JSX.Element[] | JSX.Element | null => {
    if (alarm === undefined) {
      return renderFETOccurrence(occurrence, index);
    }
    const hiddenAlarm = getHiddenAlarm(occurrence);
    if (hiddenAlarm && occurrence.alarmId === hiddenAlarm.alarmId && index === 0) {
      return (
        <div className={`${classes.hiddenAlarm}`}>
          <span>
            {occurrences!.length} event{occurrences!.length > 1 ? 's' : ''} hidden
          </span>
          <div onClick={(event: MouseEvent) => showSelectedHiddenAlarm(event, hiddenAlarm.alarmId)}>
            <Icon name={'eye'} /> Show them again
          </div>
        </div>
      );
    }

    const isSameAlarmId: boolean = (occurrence.alarmId === alarm.id || occurrence.id === alarm.id) && !hiddenAlarm;
    if (isSameAlarmId) {
      return <GanttUnit key={index} occurrence={occurrence} disableTooltip={false} />;
    }

    return null;
  };

  return (
    <div
      className={`${classes.ganttUnit} ${
        noAlarmOccurrences?.includes(`${fetNode?.name}`) && fetNode?.subTree.length === 0 ? classes.empty : ''
      }`}
    >
      {occurrences.map((occurrence, index) => renderOccurrence(occurrence, index))}
    </div>
  );
};

export default AlarmGantt;
