// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.burxfGdXcZrxjlmSUAWd{position:relative;width:100%;height:50px;min-width:.1%;display:grid;overflow-x:hidden}.burxfGdXcZrxjlmSUAWd.MaAU5PG9v_V7Mr8UlEzh{background:rgba(211,211,211,.1);opacity:.6;overflow-x:hidden;width:100%}.burxfGdXcZrxjlmSUAWd .AHcE0wsW1NKqYVuyj5Xm{position:absolute;display:flex;align-self:center;padding-left:1em;color:rgba(211,211,211,.1)}.burxfGdXcZrxjlmSUAWd .tLlGmTHM9OiRzBstIGfq{background:#000;width:max-content;align-self:center;padding:0 10px;display:flex;gap:1rem;opacity:.5}`, "",{"version":3,"sources":["webpack://./components/AlarmStream/AlarmFET/components/AlarmGantt/AlarmGantt.module.scss"],"names":[],"mappings":"AAEA,sBACE,iBAAA,CACA,UAAA,CACA,WAAA,CACA,aAAA,CACA,YAAA,CACA,iBAAA,CAEA,2CACE,+BAAA,CACA,UAAA,CACA,iBAAA,CACA,UAAA,CAGF,4CACE,iBAAA,CACA,YAAA,CACA,iBAAA,CACA,gBAAA,CACA,0BAAA,CAGF,4CACE,eAAA,CACA,iBAAA,CACA,iBAAA,CACA,cAAA,CACA,YAAA,CACA,QAAA,CACA,UAAA","sourcesContent":["$lightgray: lightgray;\n\n.ganttUnit {\n  position: relative;\n  width: 100%;\n  height: 50px;\n  min-width: 0.1%;\n  display: grid;\n  overflow-x: hidden;\n\n  &.empty {\n    background: rgba($lightgray, 0.1);\n    opacity: 0.6;\n    overflow-x: hidden;\n    width: 100%;\n  }\n\n  .noActiveAlarm {\n    position: absolute;\n    display: flex;\n    align-self: center;\n    padding-left: 1em;\n    color: rgba($lightgray, 0.1);\n  }\n\n  .hiddenAlarm {\n    background: rgb(0 0 0);\n    width: max-content;\n    align-self: center;\n    padding: 0 10px;\n    display: flex;\n    gap: 1rem;\n    opacity: 0.5;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ganttUnit": `burxfGdXcZrxjlmSUAWd`,
	"empty": `MaAU5PG9v_V7Mr8UlEzh`,
	"noActiveAlarm": `AHcE0wsW1NKqYVuyj5Xm`,
	"hiddenAlarm": `tLlGmTHM9OiRzBstIGfq`
};
export default ___CSS_LOADER_EXPORT___;
