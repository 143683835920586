import { Icon, Tooltip } from '@grafana/ui';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment';
import React, { FC, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { AlarmCallbacks, AlarmDetails as AlarmDetailsInterface, Solution } from '@sms-smart-alarm';
import { alertError, alertSuccess } from '@utils/grafana';

import { SmartAlarmStreamContext } from '../../context/SmartAlarmStreamContext';
import { AlarmNotificationsModal } from '../modals/alarm-notification/AlarmNotificationsModal';
import { AlarmSolutionsModal } from '../modals/alarm-solutions/AlarmSolutionsModal';
import { HideAlarmModal } from '../modals/hide-alarms/HideAlarmModal';
import { dateTimeZone, showHiddenAlarm } from '../utils/utils.model';

import classes from './AlarmDetails.module.scss';
import { AlarmDetailsPropertiesInterface, AlarmType } from './interfaces/alarm-details.interface';

interface DisplayModalProps {
  showHideAlarmModal: boolean;
  showSolutionModal: boolean;
  showNotificationModal: boolean;
}

interface AlarmDetailsProps {
  callbacks: AlarmCallbacks;
}

export const AlarmDetails: FC<AlarmDetailsProps> = ({ callbacks }): ReactElement => {
  const { state, dispatch } = useContext(SmartAlarmStreamContext);
  const [displayModal, setDisplayModal] = useState<DisplayModalProps>({
    showHideAlarmModal: false,
    showSolutionModal: false,
    showNotificationModal: false,
  });
  const { selectedAlarm, hiddenAlarms } = state;
  const [solutions, setSolutions] = useState<Solution[]>([]);
  const [newSolutions, setNewSolutions] = useState<string[]>([]);
  const { alarmId, alarmType, alarmName, signal, location, events } = selectedAlarm as AlarmDetailsPropertiesInterface;
  const alarm = {
    alarmId,
    name: alarmName,
    solutions: [],
  };
  const hasHiddenAlarm: boolean = hiddenAlarms.some(
    (alarm: AlarmDetailsInterface) => {
      const hiddenFrom = alarm.hiddenFrom ? dateTimeZone(alarm.hiddenFrom) : null;
      return alarm.alarmId === selectedAlarm.alarmId && (alarm.permanentlyHide || (alarm.hiddenFrom && alarm.hiddenTo && moment().isSameOrAfter(hiddenFrom!.format())));
    }
  );
  const isOnWatchlist: boolean = hiddenAlarms.some(
    (alarm: AlarmDetailsInterface) => alarm.alarmId === selectedAlarm.alarmId && alarm.onWatchlist
  );

  const fetchSolutions = useCallback(async (): Promise<void> => {
    const alarmSolutions: Solution[] = await callbacks.getAlarmSolutions(alarmId);
    setSolutions([...alarmSolutions]);
  }, [alarmId, callbacks]);

  const getNewSolutions = (alarmSolutions: Solution[]): void => {
    const solutionIds = [];
    for (const alarm of alarmSolutions) {
      const hours = moment().diff(moment(`${alarm.createdAt}`), 'hours');
      if (hours <= 24) {
        solutionIds.push(alarm.solutionId);
      }
    }
    const uniqueAlarmIds = [...new Set(solutionIds)];
    setNewSolutions([...uniqueAlarmIds]);
  };

  const showSelectedHiddenAlarm = async (): Promise<void> => {
    await showHiddenAlarm(selectedAlarm.alarmId, callbacks, dispatch);
  };

  const toggleAlarmWatchlist = async (alarmId: string, type: string): Promise<void> => {
    try {
      let watchlistAlarms: AlarmDetailsInterface[] = [];
      const result: AlarmDetailsInterface = type === 'add' ? await callbacks?.addToWatchlist(alarmId) as AlarmDetailsInterface : await callbacks?.removeFromWatchlist(alarmId) as AlarmDetailsInterface;
      watchlistAlarms = hiddenAlarms.filter((alarm: AlarmDetailsInterface) => alarm.alarmId !== alarmId);
      watchlistAlarms.push(result as AlarmDetailsInterface);

      dispatch({
        type: 'hiddenAlarms',
        payload: watchlistAlarms
      });
      alertSuccess(`Successfully ${type === 'add' ? 'added' : 'removed'} watchlist alarm.`);
    } catch (error) {
      alertError(`Error ${type === 'add' ? 'adding' : 'removing'} watchlist alarm.`);
    }
  };

  useMemo(() => getNewSolutions(solutions), [solutions]);

  useEffect(() => {
    fetchSolutions();
  }, [fetchSolutions]);

  return (
    <>
      {displayModal.showHideAlarmModal && (
        <HideAlarmModal
          alarm={alarm}
          activeModal={displayModal.showHideAlarmModal}
          callbacks={callbacks}
          setActiveModal={() => setDisplayModal({ ...displayModal, showHideAlarmModal: false })}
        />
      )}
      {displayModal.showSolutionModal && (
        <AlarmSolutionsModal
          alarm={alarm}
          solutions={solutions}
          activeModal={displayModal.showSolutionModal}
          setActiveModal={() => setDisplayModal({ ...displayModal, showSolutionModal: false })}
          setSolutions={setSolutions}
          callbacks={callbacks}
        />
      )}
      {displayModal.showNotificationModal && (
        <AlarmNotificationsModal
          alarm={alarm}
          activeModal={displayModal.showNotificationModal}
          setActiveModal={() => setDisplayModal({ ...displayModal, showNotificationModal: false })}
        />
      )}
      <div className={classes.header}>
        <p className={classes.headerText}>
          <Icon name="info-circle"></Icon> Alarm Details
        </p>
        <span
          onClick={() => {
            dispatch({ type: 'detailsPanelOpen', payload: false });
            dispatch({ type: 'clearSelectedAlarm', payload: {} });
          }}
        >
          X
        </span>
      </div>
      <div className={classes.content}>
        <div className={`${classes.alarm} ${alarmType === AlarmType.ERROR ? classes.error : classes.warning}`}>
          <Icon name="exclamation-triangle"></Icon> {`${alarmType === AlarmType.ERROR ? 'Error' : 'Warning'}`}
        </div>
        <div className={classes.info}>
          <div>
            <span>Alarm</span>
            <Tooltip content={`${alarmName}`} placement="top-start" theme="info">
              <span>{alarmName}</span>
            </Tooltip>
          </div>
          <div>
            <span>Signal</span>
            <Tooltip content={`${signal}`} placement="top-start" theme="info">
              <span>{signal}</span>
            </Tooltip>
          </div>
          <div>
            <span>Location</span>
            <Tooltip content={`${location}`} placement="top-start" theme="info">
              <span>{location}</span>
            </Tooltip>
          </div>
        </div>
        <div className={classes.footer}>
          <div className={classes.space}>
            <div>{events} events</div>
            {/*
            <div className={classes.dot}>
              1 new <span className={classes['red-dot']}></span>
            </div>
            */}
          </div>
          <div className={classes.space}>
            <div onClick={() => setDisplayModal({ ...displayModal, showSolutionModal: true })}>
              <p>{solutions.length} solutions available</p>
              <div className={classes.link}>Show solutions</div>
            </div>
            <div className={classes.dot}>
              <div className={classes.dot}>
                {newSolutions.length > 0 ? (
                  <>
                    {newSolutions.length} new <span className={classes['red-dot']}></span>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div
              className={classes.link}
              onClick={() => {
                if (!hasHiddenAlarm) {
                  setDisplayModal({ ...displayModal, showHideAlarmModal: true });
                } else {
                  showSelectedHiddenAlarm();
                }
              }}
            >
              {!hasHiddenAlarm ? 'Hide this alarm' : 'Show this alarm'}
            </div>
          <div
            className={classes.link}
            onClick={() => toggleAlarmWatchlist(selectedAlarm.alarmId, !isOnWatchlist ? 'add' : 'remove')}
          >
            {!isOnWatchlist ? 'Add to watchlist' : 'Remove from watchlist'}
          </div>
          <div
            className={classes.link}
            onClick={() => setDisplayModal({ ...displayModal, showNotificationModal: true })}
          >
            Enable notification
          </div>
        </div>
      </div>
    </>
  );
};
