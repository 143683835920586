import React, { FC, ReactElement, useContext, useRef } from 'react';

import { SmartAlarmStreamContext } from '../../../context/SmartAlarmStreamContext';
import DragZoom from '../../utils/DragZoom';

import classes from './AlarmFET.module.scss';
import Tree from './components/Tree/Tree';
import { FET } from './interfaces/alarm-fet.interface';

const AlarmFET: FC = (): ReactElement => {
  const { state } = useContext(SmartAlarmStreamContext);
  const { timeRange } = state;
  const dragAreaRef = useRef<HTMLDivElement>(null);
  const bodyContentRef = useRef<HTMLDivElement>(null);

  const dragZoom = new DragZoom(dragAreaRef, bodyContentRef, timeRange);

  return (
    <div className={classes['fet-container']}>
      <div className={classes['fet-container-body']}>
        <div
          className={classes['fet-container-body-content']}
          ref={bodyContentRef}
          onMouseLeave={dragZoom.dragAbort.bind(dragZoom)}
          onMouseDown={dragZoom.dragStart.bind(dragZoom)}
          onMouseMove={dragZoom.dragMove.bind(dragZoom)}
          onMouseUp={dragZoom.dragStop.bind(dragZoom)}
          onClickCapture={dragZoom.captureClick.bind(dragZoom)}
        >
          {state.tree.subTree.map((data: FET, index: number) => (
            <Tree fet={data} key={index} level={0} alarmLevel={0} />
          ))}
          <div className={classes['drag-select-area']} title="drag-area" ref={dragAreaRef}></div>
        </div>
      </div>
    </div>
  );
};

export default AlarmFET;
