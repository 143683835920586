import { Button, TextArea } from '@grafana/ui';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment';
import React, { BaseSyntheticEvent, ReactElement, useState, useRef } from 'react';

import { Solution } from '@sms-smart-alarm';

import { ConfirmModalContainer } from './ConfirmModalContainer';
import classes from './SolutionDisplay.module.scss';

interface SolutionDisplayProps {
  solution: Solution;
  selectContent(solution: Solution): void;
  deleteSolution(solutionId: string): void;
  updateSolution(solution: Solution): void;
}

interface ConfirmProps {
  confirmCancel: boolean;
  confirmDelete: boolean;
}

export const SolutionDisplay: React.FC<SolutionDisplayProps> = ({
  solution,
  deleteSolution,
  updateSolution,
  selectContent,
}): ReactElement => {
  const [edit, setEdit] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<ConfirmProps>({
    confirmCancel: false,
    confirmDelete: false,
  });
  const [content, setContent] = useState<string>(solution.content ?? '');
  const contentRef = useRef<string>(solution.content ?? '');

  const cancel = (): void => {
    setConfirm({ ...confirm, confirmCancel: false });
    setEdit(false);
    // Reset of state.
    setContent(solution.content ?? '');
  };

  const remove = (): void => {
    setConfirm({ ...confirm, confirmDelete: false });
    deleteSolution(solution.solutionId);
  };

  const save = (): void => {
    solution.content = content;
    updateSolution(solution);
    setEdit(false);
  };

  return (
    <>
      <header>
        {!edit && solution.content && (
          <div className={classes.solutionDateMeta}>
            <div className={classes.solutionCreated}>
              <div>
                <div className="text-muted">Created:</div>
                <div>
                  <div>{moment(solution.createdAt).format('DD.MM.YYYY, HH:mm')}</div>
                </div>
              </div>
            </div>
            <div className={classes.solutionUpdated}>
              {solution.updatedAt ? (
                <div>
                  <div className="text-muted">Updated:</div>
                  <div>
                    <div>{moment(solution.updatedAt).format('DD.MM.YYYY, HH:mm')}</div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
        <div className={classes.solutionsControls} style={{ width: `${solution.content && !edit ? '40%' : '100%'}` }}>
          <div className={classes.btnGroup}>
            <button
              className={'btn btnDefault'}
              onClick={() => {
                setEdit(!edit);
                selectContent(solution);
              }}
            >
              <i className="fa fa-pencil"></i>
            </button>
            <button className={'btn btnDefault'} onClick={() => setConfirm({ ...confirm, confirmDelete: true })}>
              <i className="fa fa-trash"></i>
            </button>
            <ConfirmModalContainer
              isOpen={confirm.confirmDelete}
              type={'delete'}
              modalType={'solutions'}
              headerText={'Do you really want to delete this solution?'}
              buttons={{
                btnOneText: 'NO, KEEP SOLUTION',
                btnTwoText: 'YES, DELETE SOLUTION',
                btnOneHandler: () => setConfirm({ ...confirm, confirmDelete: false }),
                btnTwoHandler: () => remove(),
              }}
              setConfirmCancel={() => setConfirm({ ...confirm, confirmCancel: false })}
              setConfirmDelete={() => setConfirm({ ...confirm, confirmDelete: false })}
            />
          </div>
        </div>
      </header>
      <div className={classes.solutionDescription}>
        {!edit && solution.content && <div className={classes.description}>{solution.content}</div>}
        {edit && (
          <>
            <TextArea
              className={classes.solutionTextarea}
              rows={6}
              placeholder="Please add a solution for this alarm type..."
              onChange={(event) => {
                setContent((event as BaseSyntheticEvent).target.value);
              }}
              value={content}
              data-cy={'content-solution-alarm'}
            />
            <div className={classes.solutionButtons}>
              <Button
                variant={'secondary'}
                onClick={() => {
                  if (contentRef.current !== content) {
                    setConfirm({ ...confirm, confirmCancel: contentRef.current !== content });
                  } else {
                    setEdit(!edit);
                  }
                  selectContent({} as Solution);
                }}
                data-cy={'discard-changes-solution-alarm'}
              >
                DISCARD SOLUTION
              </Button>
              <Button onClick={save} data-cy={'save-solution-alarm'} disabled={contentRef.current === content}>
                SAVE SOLUTION
              </Button>
              <ConfirmModalContainer
                isOpen={confirm.confirmCancel}
                type={'confirm'}
                modalType={'solutions'}
                headerText={'Do you really want to discard your changes?'}
                buttons={{
                  btnOneText: 'CONTINUE EDITING SOLUTION',
                  btnTwoText: 'DISCARD CHANGES',
                  btnOneHandler: () => setConfirm({ ...confirm, confirmCancel: false }),
                  btnTwoHandler: () => cancel(),
                }}
                setConfirmCancel={() => setConfirm({ ...confirm, confirmCancel: false })}
                setConfirmDelete={() => setConfirm({ ...confirm, confirmDelete: false })}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
