import uniqBy from 'lodash-es/uniqBy';

import { NotificationQualification } from '@pw-notification';
import { Alarm, AlarmDetails, Occurrence } from '@sms-smart-alarm';

import {
  FETOccurrenceProps,
  FetAlarmProps,
  FET,
} from '../components/AlarmStream/AlarmFET/interfaces/alarm-fet.interface';

export const constructTree = (alarms: Alarm[], filterWatchList: boolean, hiddenAlarms: AlarmDetails[]): FET => {
  const rootTree: FET = {
    name: 'root',
    subTree: [],
    alarms: [],
    id: '',
    errorCount: 0,
    warningCount: 0,
    plantPath: [],
    occurrences: [],
  };

  const getOrCreateTree = (tree: FET, alarm: Alarm, plantPathIndex: number): FET => {
    // Last iteration
    if (plantPathIndex === -1) {
      return tree;
    }
    const plantPath = alarm.hierarchy;
    const plantName: string = plantPath[plantPathIndex] ?? '';
    const subTree: FET | undefined = tree.subTree.find((child: FET) => child.name === plantName);
    if (subTree) {
      return getOrCreateTree(subTree, alarm, plantPathIndex - 1);
    }
    const newTree: FET = {
      name: plantName,
      id: [...tree.plantPath!, plantName].join('-'),
      plantPath: [...tree.plantPath!, plantName],
      subTree: [],
      alarms: [],
      errorCount: 0,
      warningCount: 0,
      occurrences: tree.occurrences,
    };
    tree.subTree.push(newTree);

    return getOrCreateTree(newTree, alarm, plantPathIndex - 1);
  };

  alarms.forEach((alarm: Alarm) => {

    if (filterWatchList) {
      const isOnWatchlist: boolean = hiddenAlarms.some(
        (alarmDetail: AlarmDetails) => alarmDetail.alarmId === alarm.alarm_id && alarmDetail.onWatchlist
      );
      if (!isOnWatchlist) {
        return;
      }
    }

    const tree: FET = getOrCreateTree(rootTree, alarm, alarm.hierarchy.length - 1);
    const occurrences: FETOccurrenceProps[] = mapOccurrences(alarm.occurrences, alarm.hierarchy);
    tree.occurrences?.push(...uniqBy(occurrences, (occ: FETOccurrenceProps) => occ.severity && occ.id && occ.startedAt && occ.endedAt));

    tree.alarms.push({
      id: alarm.alarm_id,
      name: alarm.name,
      tag: alarm.signal,
      location: alarm.location,
      occurrences,
      ...mapOccurrenceInfo(alarm.occurrences),
    });
  });
  return rootTree;
};

const mapOccurrenceInfo = (occurrences: Occurrence[]): FetAlarmProps => {
  if (occurrences.length === 0) {
    return { severity: undefined, summary: undefined, description: undefined };
  }

  return {
    severity: occurrences[0].qualification === NotificationQualification.WARNING ? 'warning' : 'error',
    summary: occurrences[0].description,
    description: occurrences[0].description,
  };
};

const mapOccurrences = (occurrences: Occurrence[], plantPaths: string[]): FETOccurrenceProps[] => {
  return occurrences.map((occurrence) => ({
    id: occurrence.id,
    startedAt: occurrence.start,
    endedAt: occurrence.end,
    acknowledgedAt: occurrence.acknowledge,
    alarmId: occurrence.alarm_id,
    severity: occurrence.qualification === 2 ? 'warning' : 'error',
    factoryElementIds: plantPaths,
  }));
};
