// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EbXZd80SXtgsIr5uMeE4{position:relative;overflow:visible}.EbXZd80SXtgsIr5uMeE4 .x3PC7kbIKFNPUV2REnw1{overflow:visible;font-size:13px;margin-right:0;color:#8de971}`, "",{"version":3,"sources":["webpack://./components/AlarmStream/Live/Live.module.scss"],"names":[],"mappings":"AAEA,sBACE,iBAAA,CACA,gBAAA,CAEA,4CACE,gBAAA,CACA,cAAA,CACA,cAAA,CACA,aAVQ","sourcesContent":["$limegreen: #8de971;\n\n.streamDate {\n  position: relative;\n  overflow: visible;\n\n  .streamDateText {\n    overflow: visible;\n    font-size: 13px;\n    margin-right: 0;\n    color: $limegreen;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"streamDate": `EbXZd80SXtgsIr5uMeE4`,
	"streamDateText": `x3PC7kbIKFNPUV2REnw1`
};
export default ___CSS_LOADER_EXPORT___;
