import { Button } from '@grafana/ui';
import { E164Number } from 'libphonenumber-js/core';
import React, { ReactElement, useState } from 'react';

import 'react-phone-number-input/style.css';
import { AlarmModalWrapper } from '../AlarmModalWrapper';
import { AlarmNotificationsProps } from '../modal.interface';

import classes from './AlarmNotificationsModal.module.scss';

interface ModalInputProps {
  email: string;
  phone: E164Number | undefined;
}

export const AlarmNotificationsModal: React.FC<AlarmNotificationsProps> = ({
  alarm,
  activeModal,
  setActiveModal,
}): ReactElement => {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [disabled] = useState<boolean>(false);
  const [modalInput, setModalInput] = useState<ModalInputProps>({
    email: '',
    phone: undefined,
  });

  return (
    <AlarmModalWrapper
      headerText={'NOTIFICATIONS FOR THIS ALARM'}
      activeModal={activeModal}
      modalType="notifications"
      minHeight={750}
      alarmType="Error"
      alarm={alarm}
      closeModal={() => setActiveModal(false)}
    >
      <div></div>
      <>
        <div className={classes.modalSwitch}>
          <div className={classes.modalContentAlarmInfo}>
            <div
              className={`${classes.checkbox} ${isChecked ? classes.active : ''} ${disabled ? classes.disabled : ''}`}
              onClick={() => setIsChecked(!isChecked)}
            ></div>
            <label
              className={`${classes.toggleLabel} ${disabled ? classes.disabled : ''}`}
              onClick={() => setIsChecked(!isChecked)}
            >
              <b className={classes.mainLabel}>Notifications</b>
              <b>{isChecked ? 'ON' : 'OFF'}</b>
            </label>
          </div>
        </div>
        <div className={classes.contentGrid}>
          {isChecked && (
            <div className={classes.contentLine}>
              <div className={`${classes.recipientInfo}`}>
                <div className={classes.flexRow}>
                  <div className={classes.contentInput}>
                    <label className={classes.controlLabel} htmlFor="email">
                      Email Address
                    </label>
                    <input
                      placeholder={'your@email'}
                      value={modalInput.email}
                      type={'email'}
                      onChange={(event) => setModalInput({ ...modalInput, email: event.target.value })}
                      data-cy={'email-notification-alarm'}
                    />
                    {/* <span>This email is not valid.</span> */}
                  </div>
                </div>
              </div>
              <div className={`${classes.selection}`}>
                <div className={classes.typeSelection}>
                  <div className={classes.flexRow}>
                    <label className={classes.radioButton}>
                      <input type="radio" checked={true} />
                      Instant Notification
                      <span className={classes.checkmark}></span>
                    </label>
                    <label className={classes.radioButton}>
                      <input type="radio" />
                      Frequency Monitoring
                      <span className={classes.checkmark}></span>
                    </label>
                    <label className={classes.radioButton}>
                      <input type="radio" />
                      Duration Monitoring
                      <span className={classes.checkmark}></span>
                    </label>
                  </div>
                </div>
                <div className={classes.notificationOptionDetails}></div>
              </div>
            </div>
          )}
          <div className={classes.borderBottom}></div>
          <div className={classes.footer}>
            <Button variant={'secondary'} onClick={() => setActiveModal(false)} data-cy={'cancel-notification-alarm'}>
              CANCEL
            </Button>
            <Button disabled={!modalInput.email && !modalInput.phone} data-cy={'save-notification-alarm'}>
              SAVE
            </Button>
          </div>
        </div>
      </>
    </AlarmModalWrapper>
  );
};
