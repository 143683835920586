/* eslint-disable no-restricted-imports */
import moment from 'moment';
import React, { FC, ReactElement, useCallback, useContext, useEffect, useRef } from 'react';

import './AlarmStream.scss';

import { AlarmDetails } from '@sms-smart-alarm';

import { SmartAlarmStreamCallbackContext, SmartAlarmStreamContext } from '../../context/SmartAlarmStreamContext';
import { dateTimeZone } from '../utils/utils.model';

import AlarmFET from './AlarmFET/AlarmFET';

const AlarmStream: FC = (): ReactElement => {
  const { state, dispatch } = useContext(SmartAlarmStreamContext);
  const { callbacks } = useContext(SmartAlarmStreamCallbackContext);
  const { timeRange, hiddenAlarms } = state;
  const rulerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!rulerRef.current) {
      return;
    }
    const resizeObserver = new ResizeObserver(() => {
      dispatch({ type: 'alarmStreamHeight', payload: rulerRef.current?.clientHeight || 0 });
    });
    resizeObserver.observe(rulerRef.current);
    return () => resizeObserver.disconnect();
  }, [dispatch, timeRange]);

  const updateHiddenAlarms = useCallback(
    async (mappedHiddenAlarms: Array<AlarmDetails | null>): Promise<void> => {
      if (mappedHiddenAlarms.length > 0) {
        const mappedAlarms = mappedHiddenAlarms as AlarmDetails[];
        const result: AlarmDetails[] = (await callbacks!.updateAlarmDetails(mappedAlarms)) as AlarmDetails[];
        const hiddenAlarmsData: AlarmDetails[] = [];
        for (const item of result) {
          hiddenAlarms.filter((alarm: AlarmDetails) => alarm.alarmId !== item.alarmId);
          hiddenAlarmsData.push(item);
        }
      }
    },
    [callbacks, hiddenAlarms]
  );

  const checkHiddenAlarm = useCallback(async (): Promise<void> => {
    try {
      let mappedHiddenAlarms = hiddenAlarms.map((item: AlarmDetails): AlarmDetails | null => {
        const hiddenTo = item.hiddenTo ? dateTimeZone(item.hiddenTo) : null;
        if (hiddenTo && moment().isBefore(hiddenTo!.format())) {
          return {
            alarmId: item.alarmId,
            hiddenFrom: null,
            hiddenTo: null,
            permanentlyHide: false,
          };
        }
        return null;
      });
      mappedHiddenAlarms = mappedHiddenAlarms.filter((item: AlarmDetails | null) => item);
      await updateHiddenAlarms(mappedHiddenAlarms);
    } catch (error) {}
  }, [hiddenAlarms, updateHiddenAlarms]);

  useEffect(() => {
    checkHiddenAlarm();
  }, [checkHiddenAlarm]);

  return (
    <div className="alarm-stream-container" ref={rulerRef}>
      <AlarmFET />
    </div>
  );
};

export default AlarmStream;
