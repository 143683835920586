import Tippy from '@tippyjs/react';
import React, { FC, ReactElement, useContext } from 'react';
import { followCursor } from 'tippy.js';

import { SmartAlarmStreamContext } from '../../../../../context/SmartAlarmStreamContext';

import { calculateCoordinates } from './AlarmGantt.model';
import classes from './GanttUnit.module.scss';
import { GanttUnitProperties, CoordinateProps } from './interfaces/gantt.interface';
import { AlarmTooltip } from './Tooltip/AlarmTooltip';

export const GanttUnit: FC<GanttUnitProperties> = ({ occurrence, disableTooltip }): ReactElement => {
  const { state } = useContext(SmartAlarmStreamContext);
  const { timeRange, streamWidth } = state;
  const rangeStart: number = timeRange.from.toDate().getTime();
  const rangeEnd: number = timeRange.to.toDate().getTime();
  const startedAt: number = new Date(occurrence.startedAt).getTime();
  const endedAt: number | null = occurrence.endedAt ? new Date(occurrence.endedAt).getTime() : null;

  const startTime: number = startedAt ? (startedAt > rangeStart ? startedAt : rangeStart) : rangeStart;
  const endTime: number = endedAt ? (endedAt > rangeEnd ? rangeEnd : endedAt) : rangeEnd;
  const coords: CoordinateProps = calculateCoordinates(startTime, endTime!, streamWidth, 0, rangeStart, rangeEnd);
  const ganntWidth: number = Math.abs(coords.x2 - coords.x1) || 1;

  return (
    <Tippy
      content={<AlarmTooltip occurrence={occurrence} />}
      disabled={disableTooltip}
      followCursor="horizontal"
      plugins={[followCursor]}
    >
      <div
        className={`${classes.ganttUnit}`}
        style={{
          width: `${ganntWidth}px`,
          left: `${coords.x1}px`,
          backgroundColor: `${occurrence.severity === 'error' ? 'red' : 'yellow'}`,
          zIndex: `${occurrence.severity === 'error' ? '10' : '5'}`,
        }}
      ></div>
    </Tippy>
  );
};
