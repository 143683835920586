/* eslint-disable no-restricted-imports */
import { AbsoluteTimeRange, DateTime, RawTimeRange } from '@grafana/data';
import moment from 'moment';

import { FETOccurrenceProps } from '../AlarmStream/AlarmFET/interfaces/alarm-fet.interface';

const MINUTE_AS_MS = 60000;
const HOUR_AS_MS = 3600000;
const DAY_AS_MS = 8.64e+7;

export interface FrameProps {
  duration: number;
  unit: 'seconds' | 'minutes' | 'hours' | 'days';
  humanized?: number;
  sectionDuration: number;
  segmentDuration?: number;
}

export interface TimeRangeProps {
  from: DateTime;
  to: DateTime;
  raw: RawTimeRange;
}

export const LIVE_MODE_FRAMES: FrameProps[] = [
  {
    duration: 5 * MINUTE_AS_MS,
    unit: 'minutes',
    humanized: moment.duration(5 * MINUTE_AS_MS).as('minutes'),
    sectionDuration: MINUTE_AS_MS,
  },
  {
    duration: 15 * MINUTE_AS_MS,
    unit: 'minutes',
    humanized: moment.duration(15 * MINUTE_AS_MS).as('minutes'),
    sectionDuration: 5 * MINUTE_AS_MS,
  },
  {
    duration: 30 * MINUTE_AS_MS,
    unit: 'minutes',
    humanized: moment.duration(30 * MINUTE_AS_MS).as('minutes'),
    sectionDuration: 5 * MINUTE_AS_MS,
  },
  {
    duration: 45 * MINUTE_AS_MS,
    unit: 'minutes',
    humanized: moment.duration(45 * MINUTE_AS_MS).as('minutes'),
    sectionDuration: 15 * MINUTE_AS_MS,
  },
  {
    duration: HOUR_AS_MS,
    unit: 'minutes',
    humanized: moment.duration(HOUR_AS_MS).as('minutes'),
    sectionDuration: 15 * MINUTE_AS_MS,
  },
  {
    duration: 2 * HOUR_AS_MS,
    unit: 'hours',
    humanized: moment.duration(2 * HOUR_AS_MS).as('hours'),
    sectionDuration: 30 * MINUTE_AS_MS,
  },
  {
    duration: 4 * HOUR_AS_MS,
    unit: 'hours',
    humanized: moment.duration(4 * HOUR_AS_MS).as('hours'),
    sectionDuration: HOUR_AS_MS,
  },
  {
    duration: 6 * HOUR_AS_MS,
    unit: 'hours',
    humanized: moment.duration(6 * HOUR_AS_MS).as('hours'),
    sectionDuration: HOUR_AS_MS,
  },
  {
    duration: 8 * HOUR_AS_MS,
    unit: 'hours',
    humanized: moment.duration(8 * HOUR_AS_MS).as('hours'),
    sectionDuration: 2 * HOUR_AS_MS,
  },
  {
    duration: 10 * HOUR_AS_MS,
    unit: 'hours',
    humanized: moment.duration(10 * HOUR_AS_MS).as('hours'),
    sectionDuration: 2 * HOUR_AS_MS,
  },
  {
    duration: 12 * HOUR_AS_MS,
    unit: 'hours',
    humanized: moment.duration(12 * HOUR_AS_MS).as('hours'),
    sectionDuration: 2 * HOUR_AS_MS,
  },
  {
    duration: 14 * HOUR_AS_MS,
    unit: 'hours',
    humanized: moment.duration(14 * HOUR_AS_MS).as('hours'),
    sectionDuration: 2 * HOUR_AS_MS,
  },
  {
    duration: 16 * HOUR_AS_MS,
    unit: 'hours',
    humanized: moment.duration(16 * HOUR_AS_MS).as('hours'),
    sectionDuration: 4 * HOUR_AS_MS,
  },
  {
    duration: 18 * HOUR_AS_MS,
    unit: 'hours',
    humanized: moment.duration(18 * HOUR_AS_MS).as('hours'),
    sectionDuration: 3 * HOUR_AS_MS,
  },
  {
    duration: 20 * HOUR_AS_MS,
    unit: 'hours',
    humanized: moment.duration(20 * HOUR_AS_MS).as('hours'),
    sectionDuration: 4 * HOUR_AS_MS,
  },
  {
    duration: 22 * HOUR_AS_MS,
    unit: 'hours',
    humanized: moment.duration(22 * HOUR_AS_MS).as('hours'),
    sectionDuration: 4 * HOUR_AS_MS,
  },
  {
    duration: 24 * HOUR_AS_MS,
    unit: 'hours',
    humanized: moment.duration(24 * HOUR_AS_MS).as('hours'),
    sectionDuration: 4 * HOUR_AS_MS,
  }
];

export const HISTORY_MODE_FRAMES: FrameProps[] = [
  {
    duration: 3 * DAY_AS_MS,
    unit: 'days',
    humanized: moment.duration(3 * DAY_AS_MS).as('days'),
    sectionDuration: DAY_AS_MS,
    segmentDuration: 24 * HOUR_AS_MS
  },
  {
    duration: 5 * DAY_AS_MS,
    unit: 'days',
    humanized: moment.duration(5 * DAY_AS_MS).as('days'),
    sectionDuration: 24 * HOUR_AS_MS,
    segmentDuration: DAY_AS_MS
  },
  {
    duration: 8 * DAY_AS_MS,
    unit: 'days',
    humanized: moment.duration(8 * DAY_AS_MS).as('days'),
    sectionDuration: DAY_AS_MS,
    segmentDuration: 24 * HOUR_AS_MS
  },
  {
    duration: 15 * DAY_AS_MS,
    unit: 'days',
    humanized: moment.duration(15 * DAY_AS_MS).as('days'),
    sectionDuration: 5 * DAY_AS_MS,
    segmentDuration: DAY_AS_MS
  },
  {
    duration: 30 * DAY_AS_MS,
    unit: 'days',
    humanized: moment.duration(30 * DAY_AS_MS).as('days'),
    sectionDuration: 7 * DAY_AS_MS,
    segmentDuration: DAY_AS_MS
  },
  {
    duration: 120 * DAY_AS_MS,
    unit: 'days',
    humanized: moment.duration(120 * DAY_AS_MS).as('days'),
    sectionDuration: 40 * DAY_AS_MS,
    segmentDuration: 10 * DAY_AS_MS
  },
  {
    duration: 180 * DAY_AS_MS,
    unit: 'days',
    humanized: moment.duration(180 * DAY_AS_MS).as('days'),
    sectionDuration: 30 * DAY_AS_MS,
    segmentDuration: 5 * DAY_AS_MS
  },
  {
    duration: 365 * DAY_AS_MS,
    unit: 'days',
    humanized: moment.duration(365 * DAY_AS_MS).as('days'),
    sectionDuration: 120 * DAY_AS_MS,
    segmentDuration: 40 * DAY_AS_MS
  },
];

export interface RangeProps {
  start: number;
  end: number;
}

export interface AlarmTooltipProperties {
  occurrence: FETOccurrenceProps;
}

export interface GridlineProps {
  formattedTime: string;
  left: number;
}

export interface CurrentDateProps {
  isCurrentYear: boolean;
  isLive: boolean;
}

export interface TimeFrameContainerProps {
  onChangeTimeRange: (timeRange: AbsoluteTimeRange) => void;
}
